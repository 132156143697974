var questionUtils = {
    isQuestionIncluded (topic, questionsToShow) {
        const _includeAllQuestions = '*'
        let included = false

        if (questionsToShow.includes(topic)) {
          included = true
        } else if (questionsToShow.includes(_includeAllQuestions)) {
          included = true
        } else {
          included = false
        }
        return included
    },
    isCardIncluded (topic, questionsToShow) {
      /** TODO:
       *    Consider implementing 'correctly', e.g. making the check
       *    overlap in array instead if a single lead topic. For now
       *    it is just implemented to meet the current requirments.
      */
      return this.isQuestionIncluded(topic, questionsToShow)
    },
  }

  export default questionUtils
