const responseOptions = [
    {
      label: 'OPTION_EVERY_SIX_MONTHS_RELATED_TO_ANAMNESIS_HOW_OFTEN_GO_TO_DENTIST',
      value: '6months',
    },
    {
      label: 'OPTION_YEARLY_RELATED_TO_ANAMNESIS_HOW_OFTEN_GO_TO_DENTIST',
      value: 'yearly',
    },
    {
        label: 'OPTION_AS_NEEDED_RELATED_TO_ANAMNESIS_HOW_OFTEN_GO_TO_DENTIST',
        value: 'per-need',
      },
]

export default Object.freeze(responseOptions)
