<template>
  <div>
    <validation-observer v-slot="{ valid }">
      <anamnesis-widget-container
        v-model="step"
        v-scroll="onScroll"
        :active-step="step"
        :steps="stepsAsFlatList"
        :available-steps="availableSteps"
        :is-loading="isLoading"
        :header-height="headerHeight"
        :show-avatar-toolbar="showAvatarToolbar"
        :show-close-button="showCloseButton"
        class="mx-auto"
        @click:next="next(valid)"
        @click:prev="stepBack()"
      >
        <!-- 0th page - Welcome page  -->
        <v-tab-item class="pb-4">
          <v-row :style="`opacity: ${headerTextOpacity};`">
            <v-col
              cols="12"
              md="12"
            >
              <v-slide-x-transition>
                <div
                  class="mb-12"
                  style="color: white"
                >
                  <v-card-title class="mb-0">
                    {{ $t("WELCOME_TO_ANAMNESIS_WIDGET_TOP_LEVEL_GREETING") }}
                    👋
                  </v-card-title>
                  <v-card-text>
                    <div class="subtitle-1">
                      {{ $t("WELCOME_TO_ANAMNESIS_WIDGET_SUBTITLE_GREETING") }}
                    </div>
                  </v-card-text>
                </div>
              </v-slide-x-transition>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="11">
              <v-expand-transition>
                <v-card class="mt-n12">
                  <v-progress-linear
                    absolute
                    top
                    height="2"
                    color="primary"
                  />

                  <v-card-title class="widget-card-title">
                    {{ $t("ANAMNESIS_TAB_HEADING_HOW_IT_WORKS") }}
                  </v-card-title>
                  <v-card-text>
                    <p>
                      <v-icon>mdi-check</v-icon>
                      {{ $t("ANAMNESIS_HOW_IT_WORKS_CARD_STEP_1") }}
                    </p>
                    <p>
                      <v-icon>mdi-check</v-icon>
                      {{ $t("ANAMNESIS_HOW_IT_WORKS_CARD_STEP_2") }}
                    </p>
                    <p>
                      <v-icon>mdi-check</v-icon>
                      {{ $t("ANAMNESIS_HOW_IT_WORKS_CARD_STEP_3") }}
                    </p>
                  </v-card-text>

                  <v-divider class="mx-4" />

                  <v-card-actions>
                    <v-avatar class="ml-3">
                      <v-img
                        :src="$t('PROVIDER_DENTIST_PORTRAIT_URL')"
                        :lazy-src="
                          $t('PLACEHOLDER_PROVIDER_DENTIST_PORTRAIT_URL')
                        "
                      />
                    </v-avatar>
                    <v-card-text>
                      {{ $t("PROVIDER_DENTIST_NAME_WITH_TITLE") }}
                    </v-card-text>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-card>
                <v-progress-linear
                  absolute
                  top
                  height="2"
                  color="primary"
                />
                <v-card-title class="widget-card-title">
                  {{ $t("CUSTOM_ANAMNESIS_WELCOME_CARD_TITLE") }}
                </v-card-title>
                <v-card-text>
                  <div class="mb-6">
                    {{ $t("CUSTOM_ANAMNESIS_WELCOME_BODY") }}
                  </div>
                  <v-row justify="space-between">
                    <v-col cols="auto">
                      <v-img
                        :src="$t('CUSTOM_ANAMNESIS_WELCOME_ILLUSTRATION_LINK')"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <!-- Question pages  -->
        <template v-for="(item, key, index) in questionStepList">
          <v-tab-item
            :key="`question-step-${index}`"
            class="pb-4"
          >
            <v-row>
              <v-col cols="12">
                <v-card
                  v-if="$t(item.includeCard1) === 'true' ? true : false"
                  class="mb-4"
                >
                  <v-progress-linear
                    absolute
                    top
                    height="2"
                    color="primary"
                  />
                  <v-card-title v-if="$t(item.titleCard1)">
                    {{ $t(item.titleCard1) }}
                  </v-card-title>
                  <v-card-subtitle v-if="$t(item.subtitleCard1)">
                    {{ $t(item.subtitleCard1) }}
                  </v-card-subtitle>

                  <v-card-text>
                    <anamnesis-questions
                      :questions-to-show="$t(item.questionsListCard1)"
                    />
                  </v-card-text>
                </v-card>

                <v-card
                  v-if="$t(item.includeCard2) === 'true' ? true : false"
                  class="mb-4"
                >
                  <v-progress-linear
                    absolute
                    top
                    height="2"
                    color="primary"
                  />
                  <v-card-title v-if="$t(item.titleCard2)">
                    {{ $t(item.titleCard2) }}
                  </v-card-title>
                  <v-card-subtitle v-if="$t(item.subtitleCard2)">
                    {{ $t(item.subtitleCard2) }}
                  </v-card-subtitle>

                  <v-card-text>
                    <anamnesis-questions
                      :questions-to-show="$t(item.questionsListCard2)"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </template>

        <!-- Submit page -->
        <v-tab-item class="pb-4">
          <!-- TODO: Extract tab into @/views/widgets/components/tabs/ConfirmSubmit.vue -->
          <v-card flat>
            <v-card-text>
              <div class="widget-display-2 mb-6 mt-6">
                <v-icon x-large>
                  mdi-checkbox-marked-circle-outline
                </v-icon>
              </div>
              <div class="widget-display-2 font-weight-light mb-6">
                {{ $t("TAB_HEADING_SUBMIT_ANAMNESIS") }}
              </div>

              <div>
                <p>{{ $t("INFORMED_CONSENT_WHY_TEXT") }}</p>
                <v-checkbox
                  v-model="isInformedConsentGiven"
                  :label="informedConsentAction.label"
                />

                <p>
                  {{ $t("WRAPPER_TEXT_FOR_LEGAL_LINKS_PART_1") }}
                  <a
                    href="#"
                    class="mr-0 grey--text text--darken-3"
                    @click="
                      (iframeDialog = true),
                      (iframeLoading = true),
                      calculateIframeHeight(),
                      loadLinkInIframe(privacyPolicyReference.link)
                    "
                    v-text="privacyPolicyReference.text"
                  />
                  {{ $t("WRAPPER_TEXT_FOR_LEGAL_LINKS_PART_2") }}
                  <a
                    href="#"
                    class="mr-0 grey--text text--darken-3"
                    @click="
                      (iframeDialog = true),
                      (iframeLoading = true),
                      calculateIframeHeight(),
                      loadLinkInIframe(informedConsentReference.link)
                    "
                    v-text="informedConsentReference.text"
                  />
                  {{ $t("WRAPPER_TEXT_FOR_LEGAL_LINKS_PART_3") }}
                </p>
                <p class="grey--text text--darken-2 mt-10">
                  {{ $t("POWERED_BY_ADENT_HEALTH_TEXT_1")
                  }}<v-icon>mdi-camera</v-icon>{{ $t("POWERED_BY_ADENT_HEALTH_TEXT_2") }}
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item class="pb-4">
          <!-- TODO: Extract tab into @/views/widgets/components/tabs/ConfirmSubmit.vue -->
          <v-card flat>
            <v-card-text>
              <div class="widget-display-2 font-weight-light mb-6 mt-6">
                {{ $t("TAB_HEADING_ANAMNESIS_FINISH_PAGE") }}
              </div>

              <!-- <div class="font-weight-light mb-6 mt-12">
                {{ $t('SHARE_WITH_OTHER_NATIVE_SHARE_LINK_HEADING') }}
              </div> -->
              <!-- <div class="font-weight-light mb-6">
                {{ $t('SHARE_WITH_OTHER_NATIVE_SHARE_LINK_HEADING_2') }}
              </div> -->
            </v-card-text>
          </v-card>

          <v-row v-if="hasDeepLinkToApp">
            <v-col>
              <v-card>
                <v-progress-linear
                  absolute
                  top
                  height="2"
                  color="primary"
                />
                <v-card-title>
                  {{ $t("DOWNLOAD_APP_CARD_TITLE") }}
                </v-card-title>

                <v-card-subtitle>
                  {{ $t("DOWNLOAD_APP_CARD_SUBTITLE") }}
                </v-card-subtitle>

                <v-divider />

                <v-card-text>
                  <v-row class="mt-2">
                    <v-icon> mdi-numeric-1 </v-icon>
                    <div class="caption">
                      {{ $t("DOWNLOAD_APP_STEP_1_TEXT") }}
                    </div>
                  </v-row>
                  <v-row class="mt-2">
                    <v-icon> mdi-numeric-2 </v-icon>
                    <div class="caption">
                      {{ $t("DOWNLOAD_APP_STEP_2_TEXT") }}
                    </div>
                  </v-row>
                  <v-row class="mt-2 mb-2">
                    <v-icon> mdi-numeric-3 </v-icon>
                    <div class="caption">
                      {{ $t("DOWNLOAD_APP_STEP_3_TEXT") }}
                    </div>
                  </v-row>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                  <v-btn
                    color="grey"
                    text
                    @click="copyDeepLinkToClipboard()"
                  >
                    {{ $t("SHARE_WITH_OTHER_COPY_RAW_LINK_TEXT") }}
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    min-width="100"
                    color="primary"
                    @click="downloadNative()"
                  >
                    {{ $t("DOWNLOAD_APP_TEXT") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-dialog
          v-model="missingMinimumRequiredFields"
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">
              {{ $t("DID_YOU_MISS_ADDING_MINIMUM_FIELDS_FOR_ANAMNESIS_TITLE") }}
            </v-card-title>

            <v-card-text>
              {{
                $t("DID_YOU_MISS_ADDING_MINIMUM_FIELDS_FOR_ANAMNESIS_BODY_TEXT")
              }}
            </v-card-text>

            <v-card-actions>
              <v-spacer />

              <v-btn
                color="success darken-1"
                text
                @click="stepBack(), (missingMinimumRequiredFields = false)"
              >
                {{
                  $t(
                    "DID_YOU_MISS_ADDING_MINIMUM_FIELDS_FOR_ANAMNESIS_GO_BACK_BUTTON_TEXT"
                  )
                }}
              </v-btn>

              <v-btn
                color="error"
                text
                @click="missingMinimumRequiredFields = false"
              >
                {{
                  $t(
                    "DID_YOU_MISS_ADDING_MINIMUM_FIELDS_FOR_ANAMNESIS_DISMISS_BUTTON_TEXT"
                  )
                }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </anamnesis-widget-container>
    </validation-observer>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import Store from './AnamnesisWidget.store'

  import validationApiServices from '@/views/widgets/services/validation_endpoints'
  import submitApiServices from '@/views/widgets/services/submit_endpoints'
  import widgetUrlServices from '@/views/widgets/services/widget_url_utils'
  import colorUtils from '@/views/widgets/services/color_utils'
  import hotjarLoader from '@/external/hotjar_analytics'

  import { loadLanguageAsync } from '@/i18n'

  import AnamnesisWidgetContainer from '@/views/widgets/anamnesis_widget/components/AnamnesisWidgetContainer'
  import AnamnesisQuestions from '@/views/widgets/anamnesis_widget/components/AnamnesisQuestions'

  import AnamnesisQuestionSteps from '@/views/widgets/data/AnamnesisQuestionSteps'

  export default {
    name: 'AnamnesisWidget',

    components: {
      AnamnesisWidgetContainer,
      AnamnesisQuestions,
    },

    data: () => ({
      mappedUserResponses: [],
      includedQuestions: [],

      // Other inputs
      isInformedConsentGiven: false,
      assessmentTypeUuid: 'd19718f6-ae39-470a-ae9c-3cd2e0628948', // Anamnesis Assessment Type
      widgetUuid: '',
      widgetType: '',
      landingPageType: '',

      // State
      step: 0,
      isLoading: false,
      isSubmitted: false,
      primarySnackbar: false,
      primarySnackbarText: '',
      errorSnackbar: false,
      errorMessage: '',
      errorTraceback: '',
      trafficSource: '',
      mappedQuestions: [],
      mappedAnswers: [],
      offsetTop: 0,
      headerHeight: 255,
      defaultHeaderHeightInitialScreen: 255,
      defaultHeaderHeightDuringFlow: 100,
      showAvatarToolbar: false,
      missingMinimumRequiredFields: false,
      questionStepList: AnamnesisQuestionSteps,
      showCloseButton: true,
      isConfirmationEmailSent: false,
      deepLinkToApp: null,
    }),

    computed: {
      ...mapGetters({
        userResponses: 'currentPage/userResponses',
        enumSteps: 'currentPage/enumSteps',
        //
        genericYesNoOptionsList: 'currentPage/genericYesNoOptionsList',
        bloodPressureYesNoHighLowOptionList: 'currentPage/bloodPressureYesNoHighLowOptionList',
        diabetesTypesOptionsList: 'currentPage/diabetesTypesOptionsList',
        dentistLastTimeVisitedOptionsList: 'currentPage/dentistLastTimeVisitedOptionsList',
        dentistRegularityOptionsList: 'currentPage/dentistRegularityOptionsList',
        brushingRegularityOptionsList: 'currentPage/brushingRegularityOptionsList',
        cleaningBetweenTeethRegularityOptionsList: 'currentPage/cleaningBetweenTeethRegularityOptionsList',
        cleaningBetweenTeethWithWhatOptionsList: 'currentPage/cleaningBetweenTeethWithWhatOptionsList',
        userHowIsClinicFoundOptionList: 'currentPage/userHowIsClinicFoundOptionList',
      }),
      // Steps for widget
      stepsAsFlatList () {
        const _steps = []
        let i = 0
        for (i in this.enumSteps) {
          _steps.push(this.enumSteps[i].title)
        }
        return _steps
      },

      availableSteps () {
        /** See comment for FunnelWidget. */
        const steps = []

        const STEP_W_INTRO = 0
        const STEP_W_QUESTIONS_1 = 1
        const STEP_W_QUESTIONS_2 = 2
        const STEP_W_QUESTIONS_3 = 3
        const STEP_W_QUESTIONS_4 = 4
        const STEP_W_QUESTIONS_5 = 5
        const STEP_W_QUESTIONS_6 = 6
        const STEP_W_QUESTIONS_7 = 7
        const STEP_W_QUESTIONS_8 = 8
        const STEP_W_QUESTIONS_9 = 9
        const STEP_W_FINAL_SUBMIT = 10
        const STEP_W_FINISH = 11

        // Always available
        steps.push(STEP_W_INTRO)
        steps.push(STEP_W_QUESTIONS_1)
        steps.push(STEP_W_QUESTIONS_2) // TODO: Consider to remove.
        steps.push(STEP_W_QUESTIONS_3)
        steps.push(STEP_W_QUESTIONS_4)
        steps.push(STEP_W_QUESTIONS_5)
        steps.push(STEP_W_QUESTIONS_6)
        steps.push(STEP_W_QUESTIONS_7)
        steps.push(STEP_W_QUESTIONS_8)
        steps.push(STEP_W_QUESTIONS_9)
        steps.push(STEP_W_FINAL_SUBMIT)

        const isStrictlyNeededPrivateInfoGiven = Boolean(this.email)
        if (isStrictlyNeededPrivateInfoGiven) steps.push(STEP_W_QUESTIONS_2)

        if (this.isInformedConsentGiven) steps.push(STEP_W_FINISH)

        return steps
      },

      // Legal documents and consent
      informedConsentAction () {
        return {
          label: this.$t('INFORMED_CONTENT_CHECKBOX_TEXT'),
          value: false,
        }
      },
      privacyPolicyReference () {
        return {
          text: this.$t('ADENT_PRIVACY_POLICY_TEXT'),
          link: this.$t('ADENT_PRIVACY_POLICY_LINK'),
        }
      },
      informedConsentReference () {
        return {
          text: this.$t('ADENT_INFORMED_CONSENT_AGREEMENT_TEXT'),
          link: this.$t('ADENT_INFORMED_CONSENT_AGREEMENT_LINK'),
        }
      },

      headerTextOpacity () {
        const opacityDenominator = ((this.offsetTop) * 0.1) || 1
        let opacity = 1 / opacityDenominator
        if (opacity < 0) {
          opacity = 1 // On "scrolling upwards"
        } else if (opacity < 0.1) {
          opacity = 0
        }
        return opacity
      },

      isNativeShareSupported () {
        return !!navigator.share
      },

      hasDeepLinkToApp () {
        // NOTE: Removed 20230919 for PTO-dag. Can be re-introduced after.
        return false
        // let link = this.deepLinkToApp
        // if (link === undefined) {
        //   return false
        // } else if (link === null) {
        //   return false
        // } else if (link === '') {
        //   return false
        // }
        // return link.length > 0
      },
    },

    created () {
      this.$store.loadStoreModule({ module: Store })
      const appDiv = document.getElementById('app')
      if (appDiv) { appDiv.setAttribute('style', 'background: white;') }
    },

    async mounted () {
      /** See comment for FunnelWidget.  */
      const clinicWidgetId = this.$route.query.provider
      this.clinicWidgetId = clinicWidgetId
      try {
        const response = await validationApiServices.getClinicLocales({ clinicWidgetId })
        this.widgetUuid = response.data.data.attributes.widget_uuid
        this.widgetType = response.data.data.attributes.widget_type
        this.landingPageType = response.data.data.attributes.landing_page_type
        const messages = response.data.data.attributes.clinic_widget_locales_as_json
        const fallbackLanguage = response.data.data.attributes.fallback_locales_language
        await loadLanguageAsync({ clinicWidgetId, messages, fallbackLanguage })
        this.includedQuestions = this.parseIncludedQuestions({ locales: messages })
      } catch (e) {
        this.$router.push('/not-found')
        throw e
      }

      this.setCustomThemeColor()
      await this.initializeNewAssessment()

      this.trafficSource = this.$route.query.origin

      const _embedded = 'generic_embedded' // TODO: Extract as config
      if (this.landingPageType === _embedded) {
        this.showCloseButton = false
      }

      this.loadHotjarRecorder(process.env.VUE_APP_ADENT_HOTJAR_APP_ID_ANAMNESIS, 'adent-anamnesis-hotjar-script-id')
    },

    errorCaptured (err, vm, info) {
      this.errorMessage = 'Something went wrong. Refresh the page and try again.'
      this.errorTraceback = err
      this.errorSnackbar = true
      this.isLoading = false
    },

    methods: {
      ...mapMutations({
        setIncludedQuestions: 'currentPage/setIncludedQuestions',
        clearStore: 'currentPage/clear',
      }),
      async next (valid) {
        /** Intended main flow (attempted simplest logic):
         *
         *  1) Get new assessment, and assign to clinic (done on 'mounted')
         *  2) Collect all data
         *  3) Submit all data at final submit
         */
        const fromStep = this.step
        const toStep = fromStep + 1

        // Pure rendering state
        if (fromStep >= 0) {
          this.headerHeight = this.defaultHeaderHeightDuringFlow
          this.showAvatarToolbar = true
        }

        // TODO: Add this validation when new customizable setup stabilizes.
        // const minimumFilledInBaseInfo = Boolean(this.userResponses.name && this.userResponses.birthdate)
        // const isStepToCheck = (fromStep === 1)
        // if (!minimumFilledInBaseInfo && isStepToCheck) {
        //   this.missingMinimumRequiredFields = true
        // }

        // Send confirmation email to user as soon as possible
        // const safeToSendConfirmationEmail = Boolean(
        //   this.userResponses.email.value &&
        //     !this.isConfirmationEmailSent
        // )
        // if (safeToSendConfirmationEmail) {
        //   this.triggerAutomatedEmailJourney()
        //   this.isConfirmationEmailSent = true
        // }

        // Increment steps...
        const finalSubmitStep = (fromStep === this.enumSteps.length - 2)
        const finishedStep = (fromStep === this.enumSteps.length - 1)

        if (finishedStep) {
          // Currently not logic to handle at this stage.
        } else {
          this.step = toStep
          this.$emit('click:next')
        }

        if (await this.skipToStep(toStep)) {
          this.next() // NOTE: Recursive.
        }

        // Submit form on last step...
        if (finalSubmitStep && !this.isSubmitted) {
          try {
            this._finalSubmit()
          } catch (err) {
            this.errorSnackbar = true
            this.errorMessage = 'Sorry, something went wrong while submitting.'
          }
        }
      },

      async stepBack () {
        const fromStep = this.step
        const toStep = fromStep - 1

        this.step = toStep
        this.$emit('click:stepBack')

        if (await this.skipToStep(toStep)) {
          this.stepBack() // NOTE: Recursive.
        }

        if (toStep === 0) {
          this.showAvatarToolbar = false
          this.headerHeight = this.defaultHeaderHeightInitialScreen
        }
      },

      async skipToStep (toStep) {
        /** Skips a step. In current implementation it can only occur on
         * 'question' steps.
         */
        let skip = false

        // NOTE: enumSteps are ALL steps. questionSteps are ONLY pages
        //   related to pages. Therefore we need the small mapping here.
        const stepInfo = this.enumSteps[toStep]
        const questionsPage = stepInfo.questionsPage
        if (questionsPage === undefined) { return false }

        const questionStep = this.questionStepList[questionsPage]
        if (questionStep === undefined) { return false }

        const isIncluded = this.$t(questionStep.includeStep)
        if (isIncluded !== 'true') { skip = true }

        return skip
      },

      async _finalSubmit () {
        this.isLoading = true
        const mappedUserResponses = await this.mapUserResponses()
        const serializedUserResponses = await submitApiServices.serializeAnamnesisUserResponses({
          mappedUserResponses: mappedUserResponses,
        })
        // Submit anamnesis screen is resulting in isInformedConsentGiven
        let response = await submitApiServices.submitFinal({
          assessmentUuid: this.assessmentUuid,
          serializedUserResponses: serializedUserResponses,
          isInformedConsentGiven: this.isInformedConsentGiven,
          widgetUuid: this.widgetUuid,
        })

        let deepLinkToApp = response.data.data.attributes.deeplink_to_app
        this.deepLinkToApp = deepLinkToApp
        this.isLoading = false
        this.isSubmitted = true
      },

      // async triggerAutomatedEmailJourney () {
      //   /** NOTE: It can be an "empty journey" with no emails. See backend.
      //   */
      //   const assessmentUuid = this.assessmentUuid
      //   const widgetUuid = this.widgetUuid
      //   const email = this.userResponses.email.value
      //   const name = this.userResponses.name.value
      //   await submitApiServices.manuallyTriggerEmailAutomationJourney({
      //     assessmentUuid: assessmentUuid,
      //     email: email,
      //     name: name,
      //     widgetUuid: widgetUuid,
      //   })
      // },

      calculateIframeHeight () {
        const toolbarHeight = this._getToolbarHeight()
        const windowHeight = window.innerHeight
        this.iframeHeight = windowHeight - toolbarHeight
      },
      _getToolbarHeight () {
        /** Currently hardcoded, have not succeeded to fetch dynamically via $refs.
         *
         * Dimensions from intrspection are:
         *   - 56px on small screens
         *   - 64px on bigger screens
         *
         * Using 64 leaves a slim white border in the bottom of the shown iframe
         * but it avoids any potential "overflow" (causing scroll) on the dialog.
         *
         * Intended style, but not working:
         *   `this.$refs.frameToolbar.clientHeight`
        */
        return 64
      },

      isMobile () {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      },

      mapUserOptionsInLabel (item) {
        return {
          ...item,
          label: this.$t(item.label),
        }
      },

      mapUserOptionsInText (item) {
        return {
          ...item,
          text: this.$t(item.text),
        }
      },

      onScroll (e) {
        this.offsetTop = window.scrollY // e.target.scrollY
      },

      async initializeNewAssessment () {
        const widgetUuid = this.widgetUuid
        const assessmentTypeUuid = this.assessmentTypeUuid
        const assessmentUuid = await submitApiServices.getNewAssessment({ widgetUuid, assessmentTypeUuid })
        this.assessmentUuid = assessmentUuid
      },

      parseIncludedQuestions ({ locales }) {
        const questions = []
        // Update keys as necessary. Consider to extract as config.
        const questionKeys = [
          'ANAMNESIS_PAGE_1_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
          'ANAMNESIS_PAGE_1_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
          'ANAMNESIS_PAGE_2_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
          'ANAMNESIS_PAGE_2_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
          'ANAMNESIS_PAGE_3_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
          'ANAMNESIS_PAGE_3_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
          'ANAMNESIS_PAGE_4_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
          'ANAMNESIS_PAGE_4_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
          'ANAMNESIS_PAGE_5_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
          'ANAMNESIS_PAGE_5_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
          'ANAMNESIS_PAGE_6_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
          'ANAMNESIS_PAGE_6_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
          'ANAMNESIS_PAGE_7_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
          'ANAMNESIS_PAGE_7_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
          'ANAMNESIS_PAGE_8_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
          'ANAMNESIS_PAGE_8_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
          'ANAMNESIS_PAGE_9_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
          'ANAMNESIS_PAGE_9_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
        ]
        let i = 0
        for (i in questionKeys) {
          const key = questionKeys[i]
          const _questions = locales[key]
          questions.push(..._questions)
        }
        return questions
      },

      async mapSelectedResponseToTextAndUuid ({
        selectedValue,
        selectableOptionList,
        valueToUuid,
      }) {
        // NOTE: If supporting multi-choice, this needs to reimplememted
        const _optionsList = selectableOptionList || []
        let _option = {}
        let responseRawText = ''
        let responseUuid = null
        let i = 0
        for (i in _optionsList) {
          _option = await _optionsList[i]
          const value0 = _option.value
          if (value0 === selectedValue) {
            // Get text
            responseRawText = this.$t(`${_option.label}`)
            // Map to uuid
            if (valueToUuid) {
              for (const [value1, uuid1] of Object.entries(valueToUuid)) {
                if (selectedValue === value1) {
                  responseUuid = uuid1
                }
              }
            }
            break
          }
        }
        return { value: selectedValue, text: responseRawText, uuid: responseUuid }
      },

      async concatChildResponses ({ parent = '', children = [], parentResponseValue = '' }) {
        let response

        const nonemptyChildren = children.filter(c => c !== '')
        if (nonemptyChildren.length > 0) {
          const _stringChildren = nonemptyChildren.join(', ')
          response = `${parent}. ${_stringChildren}`
        } else if (!parent) {
          // pass. placeholder is added in later serialization QUESTION_GENERIC_NOT_ANSWERED_TEXT
        } else if (parentResponseValue === 'yes') {
          response = `${parent} ${this.$t('QUESTION_GENERIC_NOT_ELABORATED_ON_TEXT')}`
        } else {
          response = `${parent}` // NOTE: In practice, this is just "No"
        }
        return response
      },

      async mapUserResponses () {
        let _mappedUserResponses = []

        let i = 0
        const userResponses = this.userResponses
        const includedQuestions = this.includedQuestions
        for (i in userResponses) {
          const response = userResponses[i]

          // Child topics will be parsed with their parents
          if (response.parentTopic) {
            continue
          }

          if (!includedQuestions.includes(response.internalTopic)) {
            continue
          }

          // Free text fields
          if (response.inputType === 'free-text') {
            _mappedUserResponses.push({
              topic: response.internalTopic, // Change to 'topic'
              response: response.value,
              question: this.$t(response.question),
              inputType: response.inputType,
              topicUuid: response.apiTopicUuid,
              topicName: response.apiTopicName,
              responseOptionUuid: null,
            })
          }

          // Choice fields (incl. their nested fields)
          if (response.inputType === 'choice') {
            if (!response.responseOptions) {
              throw Error('Expected response options for choice field')
            }

            // Step 1. Retrieve child responses, if anys
            let childResponses = []
            let j = 0
            for (j in userResponses) {
              const innerResponse = userResponses[j]

              if (!innerResponse.parentTopic) {
                continue
              }

              if (innerResponse.parentTopic === response.internalTopic) {
                let innerResponseValue
                if (innerResponse.inputType === 'free-text') {
                  innerResponseValue = innerResponse.value
                } else if (innerResponse.inputType === 'choice') {
                  let innerResponseDict = await this.mapSelectedResponseToTextAndUuid({
                    selectedValue: innerResponse.value,
                    selectableOptionList: innerResponse.responseOptions,
                    valueToUuid: innerResponse.responseOptionToUuid,
                  })
                  innerResponseValue = innerResponseDict.text
                }
                childResponses.push(innerResponseValue)
              }
            }

            // Step 2. Concat parent and child responses
            const parentResponseDict = await this.mapSelectedResponseToTextAndUuid({
              selectedValue: response.value,
              selectableOptionList: response.responseOptions,
              valueToUuid: response.responseOptionToUuid,
            })
            const parentResponseText = parentResponseDict.text
            const parentResponseUuid = parentResponseDict.uuid
            let concatResponse
            if (childResponses.length > 0) {
              concatResponse = await this.concatChildResponses({
                parent: parentResponseText,
                children: childResponses,
                parentResponseValue: response.value,
              })
            } else {
              concatResponse = parentResponseText
            }

            // Step 3. Bundle as one answer.
            _mappedUserResponses.push({
              topic: response.internalTopic, // Change to 'topic'
              response: concatResponse,
              question: this.$t(response.question),
              inputType: response.inputType,
              responseOptionUuid: parentResponseUuid,
              topicUuid: response.apiTopicUuid,
              topicName: response.apiTopicName,
            })
          }
        }
        return _mappedUserResponses
      },
      async copyDeepLinkToClipboard () {
        const link = this.deepLinkToApp // await this.buildFunnelWidgetLink()
        var _tmp = document.createElement('textarea')
        document.body.appendChild(_tmp)
        _tmp.value = link
        _tmp.select()
        document.execCommand('copy')
        document.body.removeChild(_tmp)
        this._showSuccessSnackbarMessage(this.$t('LINK_COPIED_TO_CLIPBOARD'))
      },

      async downloadNative () {
        const link = this.deepLinkToApp
        window.location.href = link
      },

      async shareLinkNativeShare () {
        if (navigator.share) {
          try {
            await navigator.share({
              title: this.$t('SHARE_WITH_OTHER_NATIVE_SHARE_LINK_TITLE'),
              text: this.$t('SHARE_WITH_OTHER_NATIVE_SHARE_LINK_TEXT'),
              url: await this.buildFunnelWidgetLink(),
            })
            const msg = this.$t('SHARE_WITH_OTHER_NATIVE_SHARE_SUCCESSFULLY_SENT_MESSAGE')
            this._showSuccessSnackbarMessage(msg)
          } catch (e) {
            // TODO: Catch and ignore AbortError only. Display other errors as failure.
            // const msg = this.$t('SHARE_WITH_OTHER_NATIVE_SHARE_FAILED_TO_SEND_MESSAGE')
            // this._showErrorShareMessage(msg, e)
          }
        } else {
          // NOTE: Case assumed never hit, since button can be hidden when unsupported
          const msg = this.$t('SHARE_WITH_OTHER_NATIVE_SHARE_NOT_SUPPORTED')
          this._showErrorShareMessage(msg, undefined)
        }
      },

      _showSuccessSnackbarMessage (msg, timeout = 500) {
        /** Pure UX utility. */
        const that = this
        setTimeout(function () {
          that.primarySnackbar = true
          that.primarySnackbarText = msg
        }, timeout)
      },

      _showErrorShareMessage (msg, error, timeout = 500) {
        /** Pure UX utility. */
        const that = this
        setTimeout(function () {
          that.errorSnackbar = true
          that.errorMessage = msg
          that.errorTraceback = error
        }, timeout)
      },

      setCustomThemeColor () {
        /** See note for Funnel Widget. */
        const _colorFromLocales = 'PROVIDER_THEME_COLOR'
        let customThemeColor
        if (colorUtils.isValidHex(this.$t(_colorFromLocales))) {
          customThemeColor = this.$t(_colorFromLocales)
        }
        // TODO: Deprecate color from URL.
        const colorFromUrl = this.$route.query.color
        if (colorUtils.isValidHex(colorFromUrl)) {
          customThemeColor = colorFromUrl
        }

        const isColorCustomized = (customThemeColor && !(customThemeColor === _colorFromLocales))
        if (isColorCustomized) {
          if (!customThemeColor.startsWith('#')) {
            customThemeColor = `#${customThemeColor}`
          }
          if (colorUtils.isValidHex(customThemeColor)) {
            this.$vuetify.theme.themes.light.primary = customThemeColor
          } else {
            // Add airbrake
          }
        }
      },

      buildFunnelWidgetLink () {
        const baseUrl = process.env.VUE_APP_GENERIC_LANDING_PAGE_BASE_URL
        return widgetUrlServices.getFunnelWidgetUrl({ clinicWidgetId: this.clinicWidgetId, baseUrl: baseUrl })
      },

      loadHotjarRecorder (appId, divId) {
        if (!appId) { return }
        const hotjarTemplate = hotjarLoader.load(appId)
        let loaderScript = document.createElement('script')
        loaderScript.setAttribute('id', divId)
        loaderScript.innerHTML = hotjarTemplate
        document.head.appendChild(loaderScript)
      },
    },
  }
</script>

<style lang="sass">
.widget-display-2
  font-size: 18px !important
</style>
