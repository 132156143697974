<template>
  <form>
    <!-- NOTE: All flat free-text (one-line) questions are built here -->
    <template v-for="(resource, j) in userResponseResources">
      <div
        v-if="
          resource.displayedFormType &&
            resource.displayedFormType === 'flatFreeTextField' &&
            isQuestionIncluded(resource.internalTopic)
        "
        :key="`generic-flat-free-text-question-${j}`"
      >
        <validation-provider v-slot="{ errors }">
          <v-text-field
            :key="`${resource.internalTopic}-${j}-generic-flat-free-text-question`"
            v-model="internalUserResponses[resource.internalTopic]"
            :error-messages="errors"
            :label="$t(resource.question)"
            :prepend-icon="resource.icon"
            @change="
              updateResponse(
                resource.internalTopic,
                internalUserResponses[resource.internalTopic]
              )
            "
          />
        </validation-provider>
      </div>
    </template>

    <!-- NOTE: All flat free-text (multi-line) questions are built here -->
    <template v-for="(resource, j) in userResponseResources">
      <div
        v-if="
          resource.displayedFormType &&
            resource.displayedFormType === 'flatFreeTextFieldLong' &&
            isQuestionIncluded(resource.internalTopic)
        "
        :key="`generic-flat-free-text-long-question-${j}`"
      >
        <validation-provider v-slot="{ errors }">
          <v-textarea
            :key="`${resource.internalTopic}`"
            v-model="internalUserResponses[resource.internalTopic]"
            :error-messages="errors"
            :label="$t(resource.question)"
            filled
            @change="
              updateResponse(
                resource.internalTopic,
                internalUserResponses[resource.internalTopic]
              )
            "
          />
        </validation-provider>
      </div>
    </template>

    <!-- NOTE: All trivial 'yes-no-with-nested-free-text-on-yes' are built here -->
    <template v-for="(resource, k) in userResponseResources">
      <div
        v-if="
          resource.displayedFormType &&
            resource.displayedFormType === 'nestedYesNoOptionListFreeText' &&
            isQuestionIncluded(resource.internalTopic)
        "
        :key="`nested-yes-no-option-list-free-text-${k}`"
      >
        <nested-yes-no-free-text
          :internal-topic="resource.internalTopic"
          :question="resource.question"
          :child-question="
            resource.childQuestion
              ? resource.childQuestion
              : 'QUESTION_GENERIC_IF_SO_WHICH_ONE'
          "
          @updateResponse="updateComponentResponse"
          @updateWhichOneText="updateComponentResponse"
        />
        <v-divider class="mb-3" />
      </div>
    </template>

    <!-- Section: Admin - Collect info and insurance  -->

    <div v-if="isQuestionIncluded('agreeToCollectMedicalInfo')">
      <p class="font-weight-light mb-6">
        {{
          $t("QUESTION_RELATED_TO_ANAMNESIS_AGREE_TO_COLLECT_MEDICAL_HISTORY")
        }}
      </p>
      <v-radio-group
        v-model="isAgreeToCollectMedicalInfo"
        :mandatory="false"
        row
      >
        <template v-for="(item, i) in computedGenericYesNoOptionsList">
          <v-radio
            :key="`collect-medical-info-${i}`"
            :label="item.label"
            :value="item.value"
            @change="updateResponse('agreeToCollectMedicalInfo', item.value)"
          />
        </template>
      </v-radio-group>

      <div
        v-show="isAgreeToCollectMedicalInfo === 'no' ? true : false"
        class="font-weight-light mb-6 error--text"
      >
        {{
          $t(
            "WARNING_TEXT_RELATED_TO_ANAMNESIS_AGREE_TO_COLLECT_MEDICAL_HISTORY"
          )
        }}
      </div>

      <v-divider class="mb-3" />
    </div>

    <div v-if="isQuestionIncluded('agreeToCollectFromPreviousDentist')">
      <p class="font-weight-light mb-6">
        {{
          $t("QUESTION_RELATED_TO_ANAMNESIS_COLLECT_INFO_FROM_PREVIOUS_DENTIST")
        }}
      </p>
      <v-radio-group
        v-model="isAgreeToCollectFromPreviousDentist"
        :mandatory="false"
        row
      >
        <template v-for="(item, i) in computedGenericYesNoOptionsList">
          <v-radio
            :key="`agree-to-collect-from-dentist-${i}`"
            :label="item.label"
            :value="item.value"
            @change="
              updateResponse('agreeToCollectFromPreviousDentist', item.value)
            "
          />
        </template>
      </v-radio-group>

      <div
        v-show="isAgreeToCollectFromPreviousDentist === 'yes' ? true : false"
      >
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          immediate
          name="Agree to collect"
        >
          <v-text-field
            v-model="
              internalUserResponses.agreeToCollectFromPreviousDentistWhichName
            "
            :error-messages="errors"
            :label="
              $t(
                'QUESTION_RELATED_TO_ANAMNESIS_COLLECT_INFO_FROM_PREVIOUS_DENTIST_WHICH_NAME'
              )
            "
            :hint="`*${$t('REQUIRED_FIELD')}`"
            filled
            @change="updateAgreeToCollectFromPreviousDentistWhichName()"
          />
        </validation-provider>
        <v-text-field
          v-model="
            internalUserResponses.agreeToCollectFromPreviousDentistWhichPhone
          "
          :label="
            $t(
              'QUESTION_RELATED_TO_ANAMNESIS_COLLECT_INFO_FROM_PREVIOUS_DENTIST_WHICH_PHONE'
            )
          "
          filled
          @change="updateAgreeToCollectFromPreviousDentistWhichPhone()"
        />
      </div>

      <v-divider class="mb-3" />
    </div>

    <!-- <div
      v-if="isQuestionIncluded('memberOfInsuranceCompany')"
    >
      <p class="font-weight-light mb-6">
        {{ $t('QUESTION_RELATED_HAS_INSURANCE_SYGESIKRING_DANMARK') }}
      </p>
      <v-radio-group
        v-model="isMemberOfInsuranceCompany"
        :mandatory="false"
        row
      >
        <template v-for="(item, i) in computedGenericYesNoOptionsList">
          <v-radio
            :key="`member-of-insurance-${i}`"
            :label="item.label"
            :value="item.value"
            @change="updateResponse('memberOfInsuranceCompany', item.value)"
          />
        </template>
      </v-radio-group>
      <v-divider class="mb-3" />
      <validation-provider
        v-slot="{ errors }"
        name="Insurance group"
      >
        <v-text-field
          v-show="isMemberOfInsuranceCompany === 'yes' ? true : false"
          v-model="internalUserResponses.memberOfInsuranceCompanyWhichOne"
          :error-messages="errors"
          :label="$t('QUESTION_RELATED_TO_ANAMNESIS_WHICH_INSURANCE_GROUP')"
          filled
          @change="updateMemberOfInsuranceCompanyWhichOne()"
        />
      </validation-provider>
    </div> -->

    <!-- Section: Medical history - Diseases 1/2 -->

    <div v-if="isQuestionIncluded('diabetes')">
      <p class="font-weight-light mb-6">
        {{
          $t(
            "QUESTION_RELATED_TO_ANAMNESIS_SUFFER_OF_FOLLOWING_CONDITIONS_DIABETES"
          )
        }}
      </p>
      <v-radio-group
        v-model="isDiabetes"
        :mandatory="false"
        row
      >
        <template v-for="(item, i) in computedGenericYesNoOptionsList">
          <v-radio
            :key="`diabetes-${i}`"
            :label="item.label"
            :value="item.value"
            @change="updateResponse('diabetes', item.value)"
          />
        </template>
      </v-radio-group>
      <v-card
        v-show="isDiabetes === 'yes' ? true : false"
        flat
        class="grey lighten-3"
      >
        <v-card-text>
          <p class="font-weight-light mb-6">
            {{ $t("QUESTION_GENERIC_IF_SO_WHICH_ONE") }}
          </p>
          <v-radio-group
            v-model="internalUserResponses.diabetesWhichOne"
            :mandatory="false"
            row
          >
            <template v-for="(item, i) in computedDiabetesTypesOptionsList">
              <v-radio
                :key="`diabetes-type-${i}`"
                :label="item.label"
                :value="item.value"
                @click="updateDiabetesWhichOne()"
              />
            </template>
          </v-radio-group>
        </v-card-text>
      </v-card>
      <v-divider class="mb-3" />
    </div>

    <div v-if="isQuestionIncluded('smoker')">
      <p class="font-weight-light mb-6">
        {{ $t("QUESTION_RELATED_TO_ANAMNESIS_SMOKING") }}
      </p>
      <v-radio-group
        v-model="isSmoker"
        :mandatory="false"
        row
      >
        <template v-for="(item, i) in computedGenericYesNoOptionsList">
          <v-radio
            :key="`smoker-${i}`"
            :label="item.label"
            :value="item.value"
            @change="updateResponse('smoker', item.value)"
          />
        </template>
      </v-radio-group>
      <validation-provider
        v-slot="{ errors }"
        name="Smoking amount"
      >
        <v-text-field
          v-show="isSmoker === 'yes' ? true : false"
          v-model="internalUserResponses.smokerWhichOne"
          :error-messages="errors"
          :label="$t('QUESTION_RELATED_TO_ANAMNESIS_SMOKING_HOW_MUCH_DAILY')"
          filled
          @change="updateSmokerWhichOne()"
        />
      </validation-provider>
      <v-divider class="mb-3" />
    </div>

    <div v-if="isQuestionIncluded('dentistLastTimeVisited')">
      <p class="font-weight-light mb-6">
        {{ $t("QUESTION_RELATED_TO_ANAMNESIS_LAST_TIME_DENTIST_VISITED") }}
      </p>
      <v-radio-group
        v-model="isDentistLastTimeVisited"
        :mandatory="false"
      >
        <template
          v-for="(item, i) in computedDentistLastTimeVisitedOptionsList"
        >
          <v-radio
            :key="`dentist-last-visit-${i}`"
            :label="item.label"
            :value="item.value"
            @change="updateResponse('dentistLastTimeVisited', item.value)"
          />
        </template>
      </v-radio-group>
      <v-divider class="mb-3" />
    </div>

    <div v-if="isQuestionIncluded('dentistRegularity')">
      <p class="font-weight-light mb-6">
        {{ $t("QUESTION_RELATED_TO_ANAMNESIS_HOW_OFTEN_GO_TO_DENTIST") }}
      </p>
      <v-radio-group :mandatory="false">
        <template v-for="(item, i) in computedDentistRegularityOptionsList">
          <v-radio
            :key="`dentist-regularity-${i}`"
            :label="item.label"
            :value="item.value"
            @change="updateResponse('dentistRegularity', item.value)"
          />
        </template>
      </v-radio-group>
      <v-divider class="mb-3" />
    </div>

    <div v-if="isQuestionIncluded('sourceOfDiscoveringClinic')">
      <!-- NOTE: If multi-choice needs to be supported, add `multiple` and re-implement mapping downstream. Seach `multi-choice` in repo -->
      <!-- NOTE: An alternative approach to multi-choice is to include multiple `sourceOfDiscoveringClinicSecondary` -->
      <v-select
        v-model="internalUserResponses.sourceOfDiscoveringClinic"
        :label="$t('QUESTION_RELATED_TO_SOURCE_OF_DISCOVERING_CLINIC')"
        :items="computedUserHowIsClinicFoundOptionList"
        item-text="label"
        item-value="value"
        @change="
          updateResponse(
            'sourceOfDiscoveringClinic',
            internalUserResponses.sourceOfDiscoveringClinic
          )
        "
      />
    </div>

    <div v-if="internalUserResponses.sourceOfDiscoveringClinic !== ''">
      <v-select
        v-model="internalUserResponses.sourceOfDiscoveringClinicSecondary"
        :label="
          $t('QUESTION_RELATED_TO_SOURCE_OF_DISCOVERING_CLINIC_SECONDARY')
        "
        :items="computedUserHowIsClinicFoundOptionList"
        item-text="label"
        item-value="value"
        @change="
          updateResponse(
            'sourceOfDiscoveringClinicSecondary',
            internalUserResponses.sourceOfDiscoveringClinicSecondary
          )
        "
      />
    </div>

    <div v-if="isQuestionIncluded('brushingRegularity')">
      <p class="font-weight-light mb-6">
        {{ $t("QUESTION_RELATED_TO_ANAMNESIS_HOW_OFTEN_BRUSHING_TEETH") }}
      </p>
      <v-radio-group :mandatory="false">
        <template v-for="(item, i) in computedBrushingRegularityOptionsList">
          <v-radio
            :key="`brushing-regularity-${i}`"
            :label="item.label"
            :value="item.value"
            @change="updateResponse('brushingRegularity', item.value)"
          />
        </template>
      </v-radio-group>
      <v-divider class="mb-3" />
    </div>

    <div v-if="isQuestionIncluded('cleaningBetweenTeethRegularity')">
      <p class="font-weight-light mb-6">
        {{ $t("QUESTION_RELATED_TO_ANAMNESIS_HOW_OFTEN_CLEAN_BETWEEN_TEETH") }}
      </p>
      <v-radio-group :mandatory="false">
        <template
          v-for="(
            item, i
          ) in computedUserCleaningBetweenTeethRegularityOptionsList"
        >
          <v-radio
            :key="`brushing-regularity-${i}`"
            :label="item.label"
            :value="item.value"
            @change="
              updateResponse('cleaningBetweenTeethRegularity', item.value)
            "
          />
        </template>
      </v-radio-group>
      <v-divider class="mb-3" />
    </div>
    <div v-if="isQuestionIncluded('cleaningBetweenTeethRegularityWithWhat')">
      <p class="font-weight-light mb-6">
        {{
          $t(
            "QUESTION_RELATED_TO_ANAMNESIS_HOW_OFTEN_CLEAN_BETWEEN_TEETH_WITH_WHAT"
          )
        }}
      </p>
      <v-radio-group :mandatory="false">
        <template
          v-for="(
            item, i
          ) in computedUserCleaningBetweenTeethWithWhatOptionsList"
        >
          <v-radio
            :key="`brushing-regularity-with-${i}`"
            :label="item.label"
            :value="item.value"
            @change="
              updateResponse(
                'cleaningBetweenTeethRegularityWithWhat',
                item.value
              )
            "
          />
        </template>
      </v-radio-group>
      <v-divider class="mb-3" />
    </div>

    <div v-if="isQuestionIncluded('wishToChangeSmile')">
      <p class="font-weight-light mb-6">
        {{ $t("QUESTION_RELATED_TO_ANAMNESIS_WISH_TO_CHANGE_SMILE") }}
      </p>
      <v-radio-group
        v-model="isWishToChangeSmile"
        :mandatory="false"
        row
      >
        <template v-for="(item, i) in computedGenericYesNoOptionsList">
          <v-radio
            :key="`change-smile-${i}`"
            :label="item.label"
            :value="item.value"
            @change="updateResponse('wishToChangeSmile', item.value)"
          />
        </template>
      </v-radio-group>
      <validation-provider
        v-slot="{ errors }"
        name="Part of smile to change"
      >
        <v-expand-transition>
          <v-textarea
            v-show="isWishToChangeSmile === 'yes' ? true : false"
            v-model="internalUserResponses.wishToChangeSmileWhichOne"
            :error-messages="errors"
            :label="$t('QUESTION_GENERIC_IF_SO_ELABARORATE')"
            filled
            auto-grow
            @change="updateIsWishToChangeSmileWhichOne()"
          />
        </v-expand-transition>
      </validation-provider>
      <v-divider class="mb-3" />
    </div>

    <div v-if="isQuestionIncluded('concernedOfCosts')">
      <p class="font-weight-light mb-6">
        {{ $t("QUESTION_RELATED_TO_ANAMNESIS_CONCERNED_OF_COSTS") }}
      </p>
      <v-radio-group
        v-model="isConcernedOfCosts"
        :mandatory="false"
        row
      >
        <template v-for="(item, i) in computedGenericYesNoOptionsList">
          <v-radio
            :key="`concerned-of-cost-${i}`"
            :label="item.label"
            :value="item.value"
            @change="updateResponse('concernedOfCosts', item.value)"
          />
        </template>
      </v-radio-group>
      <validation-provider
        v-slot="{ errors }"
        name="Concern of cost"
      >
        <v-expand-transition>
          <v-textarea
            v-show="isConcernedOfCosts === 'yes' ? true : false"
            v-model="internalUserResponses.concernedOfCostsWhichOne"
            :error-messages="errors"
            :label="$t('QUESTION_GENERIC_IF_SO_ELABARORATE')"
            filled
            auto-grow
            @change="updateConcernedOfCostsWhichOne()"
          />
        </v-expand-transition>
      </validation-provider>
      <v-divider class="mb-3" />
    </div>

    <div v-if="isQuestionIncluded('concernedAboutDentalTreatment')">
      <p class="font-weight-light mb-6">
        {{
          $t("QUESTION_RELATED_TO_ANAMNESIS_CONCERNED_ABOUT_DENTAL_TREATMENT")
        }}
      </p>
      <v-radio-group
        v-model="isConcernedAboutDentalTreatment"
        :mandatory="false"
        row
      >
        <template v-for="(item, i) in computedGenericYesNoOptionsList">
          <v-radio
            :key="`concerned-of-cost-${i}`"
            :label="item.label"
            :value="item.value"
            @change="
              updateResponse('concernedAboutDentalTreatment', item.value)
            "
          />
        </template>
      </v-radio-group>
      <validation-provider
        v-slot="{ errors }"
        name="Concern about treatment"
      >
        <v-expand-transition>
          <v-textarea
            v-show="isConcernedAboutDentalTreatment === 'yes' ? true : false"
            v-model="
              internalUserResponses.concernedAboutDentalTreatmentWhichOne
            "
            :error-messages="errors"
            :label="$t('QUESTION_GENERIC_IF_SO_ELABARORATE')"
            filled
            auto-grow
            @change="updateConcernedAboutDentalTreatmentWhichOne()"
          />
        </v-expand-transition>
      </validation-provider>
      <v-divider class="mb-3" />
    </div>

    <div v-if="isQuestionIncluded('alcoholConsumption')">
      <validation-provider
        v-slot="{ errors }"
        name="Alcohol consumption"
      >
        <v-text-field
          v-model="internalUserResponses.alcoholConsumption"
          :error-messages="errors"
          :label="$t('QUESTION_RELATED_TO_ANAMNESIS_ALCOHOL_CONSUMPTION')"
          @change="
            updateResponse(
              'alcoholConsumption',
              internalUserResponses.alcoholConsumption
            )
          "
        />
      </validation-provider>
    </div>

    <!-- NOTE: All 'simple' (non-nested) yes/no questions are built here -->
    <template v-for="(resource, j) in userResponseResources">
      <div
        v-if="
          resource.displayedFormType &&
            resource.displayedFormType === 'flatYesNoOptionsList' &&
            isQuestionIncluded(resource.internalTopic)
        "
        :key="`generic-yes-no-question-${j}`"
      >
        <p class="font-weight-light mb-6">
          {{ $t(resource.question) }}
        </p>
        <v-radio-group
          :mandatory="false"
          row
        >
          <template v-for="(item, i) in computedGenericYesNoOptionsList">
            <v-radio
              :key="`${resource.internalTopic}-${i}`"
              :label="item.label"
              :value="item.value"
              @change="updateResponse(resource.internalTopic, item.value)"
            />
          </template>
        </v-radio-group>
        <v-divider class="mb-3" />
      </div>
    </template>

    <!-- NOTE: All 'simple' (non-nested) 1-5 questions are built here -->
    <template v-for="(resource, j) in userResponseResources">
      <div
        v-if="
          resource.displayedFormType &&
            resource.displayedFormType === 'genericSatisfationOptionsList' &&
            isQuestionIncluded(resource.internalTopic)
        "
        :key="`generic-satisfaction-question-${j}`"
      >
        <p class="font-weight-light mb-6">
          {{ $t(resource.question) }}
        </p>
        <v-radio-group :mandatory="false">
          <template v-for="(item, i) in computedGenericSatisfationOptionsList">
            <v-radio
              :key="`${resource.internalTopic}-${i}`"
              :label="item.label"
              :value="item.value"
              @change="updateResponse(resource.internalTopic, item.value)"
            />
          </template>
        </v-radio-group>
        <v-divider class="mb-3" />
      </div>
    </template>

    <div v-if="isQuestionIncluded('otherDiseases')">
      <p class="font-weight-light mb-6">
        {{ $t("QUESTION_RELATED_TO_ANAMNESIS_OTHER_DISEASES") }}
      </p>
      <v-radio-group
        v-model="isOtherDiseases"
        :mandatory="false"
        row
      >
        <template v-for="(item, i) in computedGenericYesNoOptionsList">
          <v-radio
            :key="`other-diseases-${i}`"
            :label="item.label"
            :value="item.value"
            @change="updateResponse('otherDiseases', item.value)"
          />
        </template>
      </v-radio-group>
      <validation-provider
        v-slot="{ errors }"
        name="Other diseases"
      >
        <v-text-field
          v-show="isOtherDiseases === 'yes' ? true : false"
          v-model="internalUserResponses.otherDiseasesWhichOne"
          :error-messages="errors"
          :label="$t('QUESTION_GENERIC_IF_SO_WHICH_ONE')"
          filled
          @change="updateOtherDiseasesWhichOne()"
        />
      </validation-provider>
      <v-divider class="mb-3" />
    </div>

    <div v-if="isQuestionIncluded('pregnant')">
      <p class="font-weight-light mb-6">
        {{ $t("QUESTION_RELATED_TO_ANAMNESIS_PREGNANT") }}
      </p>
      <v-radio-group
        v-model="isPregnant"
        :mandatory="false"
        row
      >
        <template v-for="(item, i) in computedGenericYesNoOptionsList">
          <v-radio
            :key="`pregnant-${i}`"
            :label="item.label"
            :value="item.value"
            @change="updateResponse('pregnant', item.value)"
          />
        </template>
      </v-radio-group>
      <v-divider class="mb-3" />
      <validation-provider
        v-slot="{ errors }"
        name="Pregnant weeks"
      >
        <v-text-field
          v-show="isPregnant === 'yes' ? true : false"
          v-model="internalUserResponses.pregnantWhichOne"
          :error-messages="errors"
          :label="$t('QUESTION_RELATED_TO_ANAMNESIS_PREGNANT_WEEKS')"
          filled
          @change="updatePregnantWhichOne()"
        />
      </validation-provider>
    </div>

    <div v-if="isQuestionIncluded('additionalOpenTopic')">
      <p class="font-weight-light mb-6">
        {{ $t("QUESTION_RELATED_TO_ANAMNESIS_ADDITIONAL_OPEN_TOPIC") }}
      </p>
      <v-radio-group
        v-model="isAdditionalOpenTopic"
        :mandatory="false"
        row
      >
        <template v-for="(item, i) in computedGenericYesNoOptionsList">
          <v-radio
            :key="`additional-open-${i}`"
            :label="item.label"
            :value="item.value"
            @change="updateResponse('additionalOpenTopic', item.value)"
          />
        </template>
      </v-radio-group>
      <validation-provider
        v-slot="{ errors }"
        name="Liver disease"
      >
        <v-text-field
          v-show="isAdditionalOpenTopic === 'yes' ? true : false"
          v-model="internalUserResponses.additionalOpenTopicWhichOne"
          :error-messages="errors"
          :label="$t('QUESTION_GENERIC_IF_SO_WHICH_ONE')"
          filled
          @change="updateAdditionalOpenTopicWhichOne()"
        />
      </validation-provider>
      <v-divider class="mb-3" />
    </div>
  </form>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import Store from '../AnamnesisWidget.store'

  import questionUtils from '@/views/widgets/services/question_utils'

  import AnamnesisQuestions from '@/views/widgets/anamnesis_widget/data/AnamnesisQuestions'

  import NestedYesNoFreeText from '@/views/widgets/anamnesis_widget/components/response_components/NestedYesNoFreeText'

  export default {
    name: 'AnamnesisInformation',

    components: {
      NestedYesNoFreeText,
    },

    props: {
      questionsToShow: {
        type: Array[String],
        default () {
          return ['*']
        },
      },
    },

    data: () => ({
      userResponseResources: JSON.parse(JSON.stringify(AnamnesisQuestions)),

      // Conditional rendering variable: To render child-questions if relevant
      isAgreeToCollectMedicalInfo: '',
      isAgreeToCollectFromPreviousDentist: '',
      isMemberOfInsuranceCompany: '',
      isBloodPressureIssue: '',
      isDiabetes: '',
      isPregnant: '',
      isSmoker: '',
      isDentistLastTimeVisited: '',
      isWishToChangeSmile: '',
      isOtherDiseases: '',
      isAvoidBrushingSomeTeeth: '',
      isConcernedOfCosts: '',
      isConcernedAboutDentalTreatment: '',
      isAdditionalOpenTopic: '',

      // Containers of state (find cleaner solution)
      internalUserResponses: {
        birthdate: '',
        address: '',
        email: '',
        name: '',
        employment: '',
        socialSecurityNumber: '',
        postalCode: '',
        city: '',
        phone: '',
        bloodPressureIssueWhichOne: '',
        diabetesWhichOne: '',
        pregnantWhichOne: '',
        smokerWhichOne: '',
        wishToChangeSmileWhichOne: '',
        memberOfInsuranceCompanyWhichOne: '',
        agreeToCollectFromPreviousDentistWhichName: '',
        agreeToCollectFromPreviousDentistWhichPhone: '',
        reasonForChangingDentist: '',
        otherDiseasesWhichOne: '',
        avoidBrushingSomeTeethWhichOne: '',
        concernedOfCostsWhichOne: '',
        concernedAboutDentalTreatmentWhichOne: '',
        additionalOpenTopicWhichOne: '',
        sourceOfDiscoveringClinic: '',
        sourceOfDiscoveringClinicSecondary: '',
      },
    }),

    computed: {
      ...mapGetters({
        genericYesNoOptionsList: 'currentPage/genericYesNoOptionsList',
        genericSatisfationOptionsList:
          'currentPage/genericSatisfationOptionsList',
        bloodPressureYesNoHighLowOptionList:
          'currentPage/bloodPressureYesNoHighLowOptionList',
        diabetesTypesOptionsList: 'currentPage/diabetesTypesOptionsList',
        dentistLastTimeVisitedOptionsList:
          'currentPage/dentistLastTimeVisitedOptionsList',
        dentistRegularityOptionsList: 'currentPage/dentistRegularityOptionsList',
        brushingRegularityOptionsList:
          'currentPage/brushingRegularityOptionsList',
        cleaningBetweenTeethRegularityOptionsList:
          'currentPage/cleaningBetweenTeethRegularityOptionsList',
        cleaningBetweenTeethWithWhatOptionsList:
          'currentPage/cleaningBetweenTeethWithWhatOptionsList',
        userHowIsClinicFoundOptionList:
          'currentPage/userHowIsClinicFoundOptionList',
      }),
      // User response options
      // IMPORTANT:
      //   Use @click, not @change when using selectable options as "children"
      //   such as `computedDiabetesTypesOptionsList`.
      computedGenericYesNoOptionsList () {
        return this.genericYesNoOptionsList.map(
          this.mapUserResponseOptionsByLabel
        )
      },
      computedGenericSatisfationOptionsList () {
        return this.genericSatisfationOptionsList.map(
          this.mapUserResponseOptionsByLabel
        )
      },
      computedBloodPressureYesNoHighLowOptionsList () {
        return this.bloodPressureYesNoHighLowOptionList.map(
          this.mapUserResponseOptionsByLabel
        )
      },
      computedDiabetesTypesOptionsList () {
        return this.diabetesTypesOptionsList.map(
          this.mapUserResponseOptionsByLabel
        )
      },
      computedDentistLastTimeVisitedOptionsList () {
        return this.dentistLastTimeVisitedOptionsList.map(
          this.mapUserResponseOptionsByLabel
        )
      },
      computedDentistRegularityOptionsList () {
        return this.dentistRegularityOptionsList.map(
          this.mapUserResponseOptionsByLabel
        )
      },
      computedBrushingRegularityOptionsList () {
        return this.brushingRegularityOptionsList.map(
          this.mapUserResponseOptionsByLabel
        )
      },
      computedUserCleaningBetweenTeethRegularityOptionsList () {
        return this.cleaningBetweenTeethRegularityOptionsList.map(
          this.mapUserResponseOptionsByLabel
        )
      },
      computedUserCleaningBetweenTeethWithWhatOptionsList () {
        return this.cleaningBetweenTeethWithWhatOptionsList.map(
          this.mapUserResponseOptionsByLabel
        )
      },
      computedUserHowIsClinicFoundOptionList () {
        return this.userHowIsClinicFoundOptionList.map(
          this.mapUserResponseOptionsByLabel
        )
      },
    },

    created () {
      this.$store.loadStoreModule({ module: Store })
    },

    methods: {
      ...mapMutations({
        setUserResponse: 'currentPage/setUserResponse',
      }),
      updateResponse (topic, response) {
        this.setUserResponse({ topic: topic, response: response })
      },
      updateComponentResponse ({ topic, response }) {
        // Updates from inside a child component
        this.setUserResponse({ topic: topic, response: response })
      },
      updateDiabetesWhichOne () {
        const topic = 'diabetesWhichOne'
        const response = this.internalUserResponses.diabetesWhichOne
        this.updateResponse(topic, response)
      },
      updatePregnantWhichOne () {
        const topic = 'pregnantWhichOne'
        const response = this.internalUserResponses.pregnantWhichOne
        this.updateResponse(topic, response)
      },
      updateSmokerWhichOne () {
        const topic = 'smokerWhichOne'
        const response = this.internalUserResponses.smokerWhichOne
        this.updateResponse(topic, response)
      },
      updateIsWishToChangeSmileWhichOne () {
        const topic = 'wishToChangeSmileWhichOne'
        const response = this.internalUserResponses.wishToChangeSmileWhichOne
        this.updateResponse(topic, response)
      },
      updateAgreeToCollectFromPreviousDentistWhichName () {
        const topic = 'agreeToCollectFromPreviousDentistWhichName'
        const response =
          this.internalUserResponses.agreeToCollectFromPreviousDentistWhichName
        this.updateResponse(topic, response)
      },
      updateAgreeToCollectFromPreviousDentistWhichPhone () {
        const topic = 'agreeToCollectFromPreviousDentistWhichPhone'
        const response =
          this.internalUserResponses.agreeToCollectFromPreviousDentistWhichPhone
        this.updateResponse(topic, response)
      },
      updateMemberOfInsuranceCompanyWhichOne () {
        const topic = 'memberOfInsuranceCompanyWhichOne'
        const response =
          this.internalUserResponses.memberOfInsuranceCompanyWhichOne
        this.updateResponse(topic, response)
      },
      updateOtherDiseasesWhichOne () {
        const topic = 'otherDiseasesWhichOne'
        const response = this.internalUserResponses.otherDiseasesWhichOne
        this.updateResponse(topic, response)
      },
      updateAvoidBrushingSomeTeethWhichOne () {
        const topic = 'avoidBrushingSomeTeethWhichOne'
        const response =
          this.internalUserResponses.avoidBrushingSomeTeethWhichOne
        this.updateResponse(topic, response)
      },
      updateConcernedOfCostsWhichOne () {
        const topic = 'concernedOfCostsWhichOne'
        const response = this.internalUserResponses.concernedOfCostsWhichOne
        this.updateResponse(topic, response)
      },
      updateConcernedAboutDentalTreatmentWhichOne () {
        const topic = 'concernedAboutDentalTreatmentWhichOne'
        const response =
          this.internalUserResponses.concernedAboutDentalTreatmentWhichOne
        this.updateResponse(topic, response)
      },
      mapUserResponseOptionsByLabel (item) {
        return {
          ...item,
          label: this.$t(item.label),
        }
      },
      isQuestionIncluded (topic) {
        return questionUtils.isQuestionIncluded(topic, this.questionsToShow)
      },
    },
  }
</script>

<style lang="sass">
.widget-display-2
  font-size: 18px !important
</style>
