const responseOptions = [
    {
      label: 'USER_RESPONSE_OPTION_GENERIC_YES',
      value: 'yes',
    },
    {
      label: 'USER_RESPONSE_OPTION_GENERIC_NO',
      value: 'no',
    },
]

export default Object.freeze(responseOptions)
