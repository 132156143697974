const responseOptions = [
    {
      label: 'OPTION_LESS_THAN_3_MONTHS_RELATED_TO_ANAMNESIS_HOW_OFTEN_GO_TO_DENTIST',
      value: 'lessThant3Months',
    },
    {
        label: 'OPTION_LESS_THAN_6_MONTHS_RELATED_TO_ANAMNESIS_HOW_OFTEN_GO_TO_DENTIST',
        value: 'lessThant6Months',
      },
    {
      label: 'OPTION_LESS_THAN_12_MONTHS_RELATED_TO_ANAMNESIS_HOW_OFTEN_GO_TO_DENTIST',
      value: 'lessThant12Months',
    },
    {
        label: 'OPTION_LESS_THAN_24_MONTHS_RELATED_TO_ANAMNESIS_HOW_OFTEN_GO_TO_DENTIST',
        value: 'lessThant24Months',
    },
    {
        label: 'OPTION_MORE_THAN_THAT_RELATED_TO_ANAMNESIS_HOW_OFTEN_GO_TO_DENTIST',
        value: 'more',
    },
]

export default Object.freeze(responseOptions)
