const responseOptions = [
    {
      label: 'USER_RESPONSE_RELATED_TO_ANAMNESIS_SUFFER_OF_FOLLOWING_CONDITIONS_DIABETES_TYPE_1',
      value: 'type1',
    },
    {
      label: 'USER_RESPONSE_RELATED_TO_ANAMNESIS_SUFFER_OF_FOLLOWING_CONDITIONS_DIABETES_TYPE_2',
      value: 'type2',
    },
]

export default Object.freeze(responseOptions)
