const anamnesisPages = {
    'page1': {
      includeStep: 'ANAMNESIS_PAGE_1_INCLUDE',
      includeCard1: 'ANAMNESIS_PAGE_1_CARD_1_INCLUDE',
      titleCard1: 'ANAMNESIS_PAGE_1_CARD_1_TITLE',
      subtitleCard1: 'ANAMNESIS_PAGE_1_CARD_1_SUBTITLE',
      questionsListCard1: 'ANAMNESIS_PAGE_1_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
      includeCard2: 'ANAMNESIS_PAGE_1_CARD_2_INCLUDE',
      titleCard2: 'ANAMNESIS_PAGE_1_CARD_2_TITLE',
      subtitleCard2: 'ANAMNESIS_PAGE_1_CARD_2_SUBTITLE',
      questionsListCard2: 'ANAMNESIS_PAGE_1_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
    },
    'page2': {
      includeStep: 'ANAMNESIS_PAGE_2_INCLUDE',
      includeCard1: 'ANAMNESIS_PAGE_2_CARD_1_INCLUDE',
      titleCard1: 'ANAMNESIS_PAGE_2_CARD_1_TITLE',
      subtitleCard1: 'ANAMNESIS_PAGE_2_CARD_1_SUBTITLE',
      questionsListCard1: 'ANAMNESIS_PAGE_2_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
      includeCard2: 'ANAMNESIS_PAGE_2_CARD_2_INCLUDE',
      titleCard2: 'ANAMNESIS_PAGE_2_CARD_2_TITLE',
      subtitleCard2: 'ANAMNESIS_PAGE_2_CARD_2_SUBTITLE',
      questionsListCard2: 'ANAMNESIS_PAGE_2_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
    },
    'page3': {
      includeStep: 'ANAMNESIS_PAGE_3_INCLUDE',
      includeCard1: 'ANAMNESIS_PAGE_3_CARD_1_INCLUDE',
      titleCard1: 'ANAMNESIS_PAGE_3_CARD_1_TITLE',
      subtitleCard1: 'ANAMNESIS_PAGE_3_CARD_1_SUBTITLE',
      questionsListCard1: 'ANAMNESIS_PAGE_3_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
      includeCard2: 'ANAMNESIS_PAGE_3_CARD_2_INCLUDE',
      titleCard2: 'ANAMNESIS_PAGE_3_CARD_2_TITLE',
      subtitleCard2: 'ANAMNESIS_PAGE_3_CARD_2_SUBTITLE',
      questionsListCard2: 'ANAMNESIS_PAGE_3_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
    },
    'page4': {
      includeStep: 'ANAMNESIS_PAGE_4_INCLUDE',
      includeCard1: 'ANAMNESIS_PAGE_4_CARD_1_INCLUDE',
      titleCard1: 'ANAMNESIS_PAGE_4_CARD_1_TITLE',
      subtitleCard1: 'ANAMNESIS_PAGE_4_CARD_1_SUBTITLE',
      questionsListCard1: 'ANAMNESIS_PAGE_4_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
      includeCard2: 'ANAMNESIS_PAGE_4_CARD_2_INCLUDE',
      titleCard2: 'ANAMNESIS_PAGE_4_CARD_2_TITLE',
      subtitleCard2: 'ANAMNESIS_PAGE_4_CARD_2_SUBTITLE',
      questionsListCard2: 'ANAMNESIS_PAGE_4_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
    },
    'page5': {
      includeStep: 'ANAMNESIS_PAGE_5_INCLUDE',
      includeCard1: 'ANAMNESIS_PAGE_5_CARD_1_INCLUDE',
      titleCard1: 'ANAMNESIS_PAGE_5_CARD_1_TITLE',
      subtitleCard1: 'ANAMNESIS_PAGE_5_CARD_1_SUBTITLE',
      questionsListCard1: 'ANAMNESIS_PAGE_5_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
      includeCard2: 'ANAMNESIS_PAGE_5_CARD_2_INCLUDE',
      titleCard2: 'ANAMNESIS_PAGE_5_CARD_2_TITLE',
      subtitleCard2: 'ANAMNESIS_PAGE_5_CARD_2_SUBTITLE',
      questionsListCard2: 'ANAMNESIS_PAGE_5_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
    },
    'page6': {
      includeStep: 'ANAMNESIS_PAGE_6_INCLUDE',
      includeCard1: 'ANAMNESIS_PAGE_6_CARD_1_INCLUDE',
      titleCard1: 'ANAMNESIS_PAGE_6_CARD_1_TITLE',
      subtitleCard1: 'ANAMNESIS_PAGE_6_CARD_1_SUBTITLE',
      questionsListCard1: 'ANAMNESIS_PAGE_6_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
      includeCard2: 'ANAMNESIS_PAGE_6_CARD_2_INCLUDE',
      titleCard2: 'ANAMNESIS_PAGE_6_CARD_2_TITLE',
      subtitleCard2: 'ANAMNESIS_PAGE_6_CARD_2_SUBTITLE',
      questionsListCard2: 'ANAMNESIS_PAGE_6_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
    },
    'page7': {
      includeStep: 'ANAMNESIS_PAGE_7_INCLUDE',
      includeCard1: 'ANAMNESIS_PAGE_7_CARD_1_INCLUDE',
      titleCard1: 'ANAMNESIS_PAGE_7_CARD_1_TITLE',
      subtitleCard1: 'ANAMNESIS_PAGE_7_CARD_1_SUBTITLE',
      questionsListCard1: 'ANAMNESIS_PAGE_7_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
      includeCard2: 'ANAMNESIS_PAGE_7_CARD_2_INCLUDE',
      titleCard2: 'ANAMNESIS_PAGE_7_CARD_2_TITLE',
      subtitleCard2: 'ANAMNESIS_PAGE_7_CARD_2_SUBTITLE',
      questionsListCard2: 'ANAMNESIS_PAGE_7_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
    },
    'page8': {
      includeStep: 'ANAMNESIS_PAGE_8_INCLUDE',
      includeCard1: 'ANAMNESIS_PAGE_8_CARD_1_INCLUDE',
      titleCard1: 'ANAMNESIS_PAGE_8_CARD_1_TITLE',
      subtitleCard1: 'ANAMNESIS_PAGE_8_CARD_1_SUBTITLE',
      questionsListCard1: 'ANAMNESIS_PAGE_8_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
      includeCard2: 'ANAMNESIS_PAGE_8_CARD_2_INCLUDE',
      titleCard2: 'ANAMNESIS_PAGE_8_CARD_2_TITLE',
      subtitleCard2: 'ANAMNESIS_PAGE_8_CARD_2_SUBTITLE',
      questionsListCard2: 'ANAMNESIS_PAGE_8_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
    },
    'page9': {
      includeStep: 'ANAMNESIS_PAGE_9_INCLUDE',
      includeCard1: 'ANAMNESIS_PAGE_9_CARD_1_INCLUDE',
      titleCard1: 'ANAMNESIS_PAGE_9_CARD_1_TITLE',
      subtitleCard1: 'ANAMNESIS_PAGE_9_CARD_1_SUBTITLE',
      questionsListCard1: 'ANAMNESIS_PAGE_9_CARD_1_QUESTIONS_TO_INCLUDE_LIST',
      includeCard2: 'ANAMNESIS_PAGE_9_CARD_2_INCLUDE',
      titleCard2: 'ANAMNESIS_PAGE_9_CARD_2_TITLE',
      subtitleCard2: 'ANAMNESIS_PAGE_9_CARD_2_SUBTITLE',
      questionsListCard2: 'ANAMNESIS_PAGE_9_CARD_2_QUESTIONS_TO_INCLUDE_LIST',
    },
}

export default Object.freeze(anamnesisPages)
