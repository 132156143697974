<template>
  <div>
    <p class="font-weight-light mb-6">
      {{ $t(question) }}
    </p>
    <v-radio-group
      v-model="isSelected"
      :mandatory="false"
      row
    >
      <template v-for="(item, i) in computedGenericYesNoOptionsList">
        <v-radio
          :key="`${i}`"
          :label="item.label"
          :value="item.value"
          @change="updateResponse(item.value)"
        />
      </template>
    </v-radio-group>
    <validation-provider
      v-slot="{ errors }"
      name="Answer"
    >
      <v-text-field
        v-show="isSelected === 'yes'"
        v-model="whichOne"
        :error-messages="errors"
        :label="$t(childQuestion)"
        filled
        @change="updateWhichOneText()"
      />
    </validation-provider>
  </div>
</template>

<script>
  import GenericYesNoOptionsList from '@/views/widgets/data/response_options/GenericYesNoOptionsList'
  import AnamnesisQuestions from '@/views/widgets/anamnesis_widget/data/AnamnesisQuestions'

  export default {
    name: 'NestedYesNoFreeText',

    props: {
      question: {
        type: String,
        required: true,
      },
      childQuestion: {
        type: String,
        default: 'QUESTION_GENERIC_IF_SO_WHICH_ONE',
      },
      internalTopic: {
        type: String,
        required: true,
      },
    },

    data () {
      return {
        isSelected: null,
        whichOne: '',
      }
    },

    computed: {
      computedGenericYesNoOptionsList () {
        return GenericYesNoOptionsList.map(this.mapUserResponseOptionsByLabel)
      },
    },
    methods: {
      updateResponse (value) {
        this.$emit('updateResponse', { topic: this.internalTopic, response: value })
      },
      updateWhichOneText (mapping = AnamnesisQuestions) {
        let childTopic = `${this.internalTopic}WhichOne` // 'Best-guess', if not found
        for (const key in mapping) {
          if (mapping[key] && mapping[key].parentTopic === this.internalTopic) {
            childTopic = mapping[key].internalTopic
            break
          }
        }
        this.$emit('updateWhichOneText', { topic: childTopic, response: this.whichOne })
      },
      mapUserResponseOptionsByLabel (item) {
        return {
          ...item,
          label: this.$t(item.label),
        }
      },
    },
  }
</script>
