import GenericYesNoOptionsList from '@/views/widgets/data/response_options/GenericYesNoOptionsList'
import GenericSatisfationOptionsList from '@/views/widgets/data/response_options/GenericSatisfationOptionsList'
import BloodPressureYesNoHighLowOptions from '@/views/widgets/data/response_options/BloodPressureYesNoHighLowOptions'
import DiabetesTypesOptionsList from '@/views/widgets/data/response_options/DiabetesTypesOptionsList'
import DentistLastTimeVisitedOptionsList from '@/views/widgets/data/response_options/DentistLastTimeVisitedOptionsList'
import DentistRegularityOptionsList from '@/views/widgets/data/response_options/DentistRegularityOptionsList'
import BrushingRegularityOptionsList from '@/views/widgets/data/response_options/BrushingRegularityOptionsList'
import CleaningBetweenTeethRegularityOptionsList from '@/views/widgets/data/response_options/CleaningBetweenTeethRegularityOptionsList'
import CleaningBetweenTeethWithWhatOptionsList from '@/views/widgets/data/response_options/CleaningBetweenTeethWithWhatOptionsList'
import UserHowIsClinicFoundOptionList from '@/views/widgets/data/response_options/UserHowIsClinicFoundOptionList'

const rows = {
    'name': {
        question: 'QUESTION_AS_SIMPLE_LABEL_RELATED_TO_NAME',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'name',
        parentTopic: null,
        displayedFormType: 'flatFreeTextField',
        icon: 'mdi-emoticon-excited',
        apiTopicName: 'name',
        apiTopicUuid: 'b180e936-3a12-4daa-a8b2-1cd291d57c4d',
    },
    'birthdate': {
        question: 'QUESTION_RELATED_TO_BIRTHDATE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'birthdate',
        parentTopic: null,
        displayedFormType: 'flatFreeTextField',
        icon: 'mdi-calendar',
        apiTopicName: 'birthdate',
        apiTopicUuid: '57ce9953-3ea3-4042-9f48-1ea1ff842137',
    },
    'socialSecurityNumber': {
        question: 'QUESTION_AS_SIMPLE_LABEL_RELATED_TO_SOCIAL_SECURITY_NUMBER',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'socialSecurityNumber',
        parentTopic: null,
        displayedFormType: 'flatFreeTextField',
        icon: 'mdi-lock',
        apiTopicName: 'social-security-number',
        apiTopicUuid: '9b7459d9-1400-4967-9918-fa5377b324ba',
    },
    'email': {
        question: 'QUESTION_AS_SIMPLE_LABEL_RELATED_TO_EMAIL_ADDRESS',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'email',
        parentTopic: null,
        displayedFormType: 'flatFreeTextField',
        icon: 'mdi-email',
        apiTopicName: 'email',
        apiTopicUuid: '5ca015ad-06e4-4c76-9a3e-61bcd1883a9f',
    },
    'phone': {
        question: 'QUESTION_AS_SIMPLE_LABEL_RELATED_TO_PHONE_NR',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'phone',
        parentTopic: null,
        displayedFormType: 'flatFreeTextField',
        icon: 'mdi-phone',
        apiTopicName: 'phone',
        apiTopicUuid: '603da932-e144-4184-9664-b828ecfb06a5',
    },
    'address': {
        question: 'QUESTION_AS_SIMPLE_LABEL_RELATED_TO_ADDRESS',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'address',
        parentTopic: null,
        displayedFormType: 'flatFreeTextField',
        icon: 'mdi-map-marker',
        apiTopicName: 'address',
        apiTopicUuid: '5e48787e-49b6-4f43-9776-899b682afacb',
    },
    'postalCode': {
        question: 'QUESTION_AS_SIMPLE_LABEL_RELATED_TO_POSTAL_CODE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'postalCode',
        parentTopic: null,
        displayedFormType: 'flatFreeTextField',
        apiTopicName: 'postal-code',
        apiTopicUuid: 'ef668799-94ae-4377-9189-6f177faa5c5b',
    },
    'city': {
        question: 'QUESTION_AS_SIMPLE_LABEL_RELATED_TO_CITY',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'city',
        parentTopic: null,
        displayedFormType: 'flatFreeTextField',
        apiTopicName: 'city',
        apiTopicUuid: 'ed510523-543d-4dcf-bd6d-d5fe9f241034',
    },
    'employment': {
        question: 'QUESTION_AS_SIMPLE_LABEL_RELATED_TO_EMPLOYMENT',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'employment',
        parentTopic: null,
        displayedFormType: 'flatFreeTextField',
        icon: 'mdi-factory',
        apiTopicName: 'employment',
        apiTopicUuid: '4e6360b1-2794-46a2-ab8f-e544b0387fd3',
    },
    'reasonForChangingDentist': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_REASON_FOR_CHANGING_DENTIST',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'reasonForChangingDentist',
        parentTopic: null,
        displayedFormType: 'flatFreeTextFieldLong',
        apiTopicName: 'reason-for-changing-dentist',
        apiTopicUuid: '63eec789-4b7f-4bba-b42f-cd8bf367862c',
    },
    'agreeToCollectMedicalInfo': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_AGREE_TO_COLLECT_MEDICAL_HISTORY',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'agreeToCollectMedicalInfo',
        parentTopic: null,
        responseOptionToUuid: {
            'no': '2107ec95-9468-4682-adc0-1d4bac17b734',
            'yes': '45e74c4f-81dc-4c20-b708-c11858ca45d5',
        },
        apiTopicName: 'agree-to-collect-medical-info',
        apiTopicUuid: '3d122653-d244-4149-b59f-93fd0c2fbd07',
    },
    'agreeToCollectFromPreviousDentist': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_COLLECT_INFO_FROM_PREVIOUS_DENTIST',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'agreeToCollectFromPreviousDentist',
        parentTopic: null,
        responseOptionToUuid: {
            'no': 'f20fd193-89ea-43a9-b58a-45c92d912f15',
            'yes': '78bda4ee-2e26-44d7-b840-d02cb8e09052',
        },
        apiTopicName: 'agree-to-collect-from-previous-dentist',
        apiTopicUuid: '6db172dd-1bbb-4611-b10e-c4fe5b697460',
    },
    'agreeToCollectFromPreviousDentistWhichName': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_COLLECT_INFO_FROM_PREVIOUS_DENTIST_WHICH_NAME',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'agreeToCollectFromPreviousDentistWhichName',
        parentTopic: 'agreeToCollectFromPreviousDentist',
        apiTopicName: 'agree-to-collect-from-previous-dentist-which-name',
        apiTopicUuid: '2575f5e2-74ee-4a6b-b506-21e7604b129f',
    },
    'agreeToCollectFromPreviousDentistWhichPhone': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_COLLECT_INFO_FROM_PREVIOUS_DENTIST_WHICH_PHONE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'agreeToCollectFromPreviousDentistWhichPhone',
        parentTopic: 'agreeToCollectFromPreviousDentist',
        apiTopicName: 'agree-to-collect-from-previous-dentist-which-phone',
        apiTopicUuid: 'fc9e4390-3c6d-4b9b-8c15-e8d05d13902d',
    },
    'memberOfInsuranceCompany': {
        question: 'QUESTION_RELATED_HAS_INSURANCE_SYGESIKRING_DANMARK',
        childQuestion: 'QUESTION_RELATED_TO_ANAMNESIS_WHICH_INSURANCE_GROUP',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'memberOfInsuranceCompany',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': 'c42aa664-ac39-4057-b9d8-b4592063d942',
            'yes': 'ea720fd8-a783-4928-87fd-80d8817dfdec',
        },
        apiTopicName: 'member-of-insurance-company',
        apiTopicUuid: '30906d48-8750-4380-ad05-8e38d1d1177d',
    },
    'memberOfInsuranceCompanyWhichOne': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_WHICH_INSURANCE_GROUP',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'memberOfInsuranceCompanyWhichOne',
        parentTopic: 'memberOfInsuranceCompany',
        apiTopicName: 'member-of-insurance-company-which-one',
        apiTopicUuid: '01933866-c37b-4cb8-b1f3-2fd7bdc9952a',
    },
    'memberOfInsuranceDanskTandforsikring': {
        question: 'QUESTION_RELATED_HAS_INSURANCE_DANSK_TANDFORSIKRING',
        childQuestion: 'QUESTION_RELATED_HAS_INSURANCE_DANSK_TANDFORSIKRING_HOW_LONG',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'memberOfInsuranceDanskTandforsikring',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': '408cb264-c8ff-4daa-86cd-69b5fc568589',
            'yes': 'f7acb72b-8562-4947-adde-1fa71cec3fa7',
        },
        apiTopicName: 'member-of-insurance-dansk-tandforsikring',
        apiTopicUuid: 'e1088fcb-3125-48b4-8418-514e08ef2052',
    },
    'memberOfInsuranceTrygTandforsikring': {
        question: 'QUESTION_RELATED_HAS_INSURANCE_TRYG_TANDFORSIKRING',
        childQuestion: 'QUESTION_RELATED_HAS_INSURANCE_TRYG_TANDFORSIKRING_HOW_LONG',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'memberOfInsuranceTrygTandforsikring',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': 'ddbec3f4-c404-49c1-892d-abe21dc6b812',
            'yes': '9de7550a-6630-46f2-9238-b2c451f5adf0',
        },
        apiTopicName: 'member-of-insurance-tryg-tandforsikring',
        apiTopicUuid: '20ace7dd-3058-46d1-934c-513323feb319',
    },
    'receivesPublicBenefits': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_RECEIVES_PUBLIC_BENEFITS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'receivesPublicBenefits',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': 'e6a56cc5-0640-41ed-a5e2-73c05ce3515a',
            'yes': '6c19bdb5-f7eb-42d6-a6c5-183f0f046414',
        },
        apiTopicName: 'receives-public-benefits',
        apiTopicUuid: '3c833ded-1850-4a80-ac18-ad747ebd5ca1',
    },
    'receivesPublicBenefitsWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_ELABARORATE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'receivesPublicBenefitsWhichOne',
        parentTopic: 'receivesPublicBenefits',
        apiTopicName: 'receives-public-benefits-which-one',
        apiTopicUuid: '1a131c06-abd3-467e-b53a-8e3745a856e2',
    },
    'consentToSendAdministrative': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_CONSENT_TO_SEND_ADMINISTRATIVE',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'consentToSendAdministrative',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '8dbaf1a0-5001-40d2-b4b5-61db1b3a7916',
            'yes': '40b2e5ad-17c8-4446-910e-423828bdc8ec',
        },
        apiTopicName: 'consent-to-send-administrative',
        apiTopicUuid: 'cb0bc6a0-4396-4031-bfd3-1b8ebc5a8f20',
    },
    'emergencyIssue': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_EMERGENCY_ISSUE',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'emergencyIssue',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': 'e28fa2ac-23af-4e9c-809f-30d5b8ef59d6',
            'yes': 'afb9f738-6aa4-48bc-b19e-7765acc2716c',
        },
        apiTopicName: 'emergency-issue',
        apiTopicUuid: 'ce72fdca-7f4a-4c39-832a-f9ecda2dd127',
    },
    'emergencyIssueWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_WHICH_ONE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'emergencyIssueWhichOne',
        parentTopic: 'emergencyIssue',
        apiTopicName: 'emergency-issue-which-one',
        apiTopicUuid: 'c0fb1611-9ced-4c3e-8fd6-89a55616e9d1',
    },
    'allergy': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SUFFER_OF_FOLLOWING_CONDITIONS_ALLERGY',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'allergy',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': '332c1e18-e045-401c-996c-7013bd4ac5d5',
            'yes': '1cdbaed1-b74b-4bc1-b9e1-1ee6db856a7a',
        },
        apiTopicName: 'allergy',
        apiTopicUuid: '7a582fbf-d6c4-4f5f-a0f8-630db62a5785',
    },
    'allergyWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_WHICH_ONE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'allergyWhichOne',
        parentTopic: 'allergy',
        apiTopicName: 'allergy-which-one',
        apiTopicUuid: '6ed8db4a-b138-4739-aad6-4d7cadfc6de1',
    },
    'asthma': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SUFFER_OF_FOLLOWING_CONDITIONS_ASTMA',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'asthma',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '4e31faf8-e47a-4535-808d-049189b94a27',
            'yes': '93a93ea7-b856-4606-9619-835c585dce57',
        },
        apiTopicName: 'asthma',
        apiTopicUuid: 'a308edca-d1ac-42a2-b0eb-2d3eab190de4',
    },
    'diabetes': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SUFFER_OF_FOLLOWING_CONDITIONS_DIABETES',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'diabetes',
        parentTopic: null,
        apiTopicName: 'diabetes',
        apiTopicUuid: '50843cd4-cd5a-4aa0-bf48-6bbeb3b8155e',
        responseOptionToUuid: {
            'no': 'bdee035c-18c4-46e0-96c3-bf7e13302aa4',
            'yes': '1d6bac89-a01c-44d1-afc2-b9057b8efd1a',
        },
    },
    'diabetesWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_WHICH_ONE',
        value: '',
        inputType: 'choice',
        responseOptions: DiabetesTypesOptionsList,
        internalTopic: 'diabetesWhichOne',
        parentTopic: 'diabetes',
        apiTopicName: 'diabetes-which-one',
        apiTopicUuid: '244fbaf1-0c3e-48d5-8258-d8a8d68a9932',
    },
    'epilepsy': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_EPILEPSY',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'epilepsy',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        apiTopicName: 'epilepsy',
        apiTopicUuid: 'c1048dff-bb6e-42d5-9bb5-1655423df2d9',
        responseOptionToUuid: {
            'no': '30fb4dfc-e8ac-4f14-85e7-ec9c10247182',
            'yes': '8b80a90d-f866-4a0b-9b08-b3a4e9718a16',
        },
    },
    'smoker': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SMOKING',
        value: '',
        inputType: 'choice',
        internalTopic: 'smoker',
        responseOptions: GenericYesNoOptionsList,
        parentTopic: null,
        responseOptionToUuid: {
            'no': 'b1b5fa9d-d23d-4abb-b7ae-22030fdd843f',
            'yes': '3f92c763-452a-4b52-a48d-f8fb3649b386',
        },
        apiTopicName: 'smoker',
        apiTopicUuid: '730b2d0f-f7c1-43be-bcf6-a9a939961767',
    },
    'smokerWhichOne': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SMOKING_HOW_MUCH_DAILY',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'smokerWhichOne',
        parentTopic: 'smoker',
        apiTopicName: 'smoker-which-one',
        apiTopicUuid: 'bef70327-0da3-43c3-ba50-053ccf1709ec',
    },
    'heartDisease': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SUFFER_OF_FOLLOWING_CONDITIONS_HEART',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'heartDisease',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': '782efc11-1c3b-4744-ab0d-1f1ba9f2a898',
            'yes': '78b37576-2a7b-4765-ab65-f349468fde7d',
        },
        apiTopicName: 'heart-disease',
        apiTopicUuid: '4f9d632b-52ae-434c-88bf-4f18354c7310',
    },
    'heartDiseaseWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_WHICH_ONE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'heartDiseaseWhichOne',
        parentTopic: 'heartDisease',
        apiTopicName: 'heart-disease-which-one',
        apiTopicUuid: 'e523efcd-9a74-4543-aa7c-6dce5f24d9b6',
    },
    'circulatoryDisease': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SUFFER_OF_FOLLOWING_CONDITIONS_CIRCULATORY_BLOOD',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'circulatoryDisease',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': '65b4417f-0626-4688-b9c0-1b5a0a04eac9',
            'yes': '29d3ca1d-41eb-4635-b3bf-e99100b847a3',
        },
        apiTopicName: 'circulatory-disease',
        apiTopicUuid: '710ea8c1-fc69-4488-83a7-c06a786ed50e',
    },
    'circulatoryDiseaseWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_WHICH_ONE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'circulatoryDiseaseWhichOne',
        parentTopic: 'circulatoryDisease',
        apiTopicName: 'circulatory-disease-which-one',
        apiTopicUuid: '07a72fa2-efba-4365-89e4-aec0ac2ef8b6',
    },
    'bloodPressureIssue': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SUFFER_OF_FOLLOWING_CONDITIONS_BLOOD_PRESSURE',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'bloodPressureIssue',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': '1432be00-8213-4ecd-9170-f17c103ecdd9',
            'yes': 'b7635ea8-8ec2-46dd-a487-a3274fbee11c',
        },
        apiTopicName: 'blood-pressure-issue',
        apiTopicUuid: '7063ec11-25a1-4fe4-a03e-a5e0d653b014',
    },
    'bloodPressureIssueWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_WHICH_ONE',
        value: '',
        inputType: 'choice',
        responseOptions: BloodPressureYesNoHighLowOptions,
        internalTopic: 'bloodPressureIssueWhichOne',
        parentTopic: 'bloodPressureIssue',
        apiTopicName: 'blood-pressure-issue-which-one',
        apiTopicUuid: '7ab72357-e0cb-423d-a83a-39fc48f69af7',
    },
    'bleedingDisease': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_BLEEDING_DISEASE',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'bleedingDisease',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '31a007f4-3e0f-44b5-a485-12b0311d0367',
            'yes': 'a8ac323b-067f-4f1e-a5a7-2c681867e8ab',
        },
        apiTopicName: 'bleeding-disease',
        apiTopicUuid: 'd010d83d-db92-463d-b853-989f54d0ec83',
    },
    'bruiseEasily': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_BRUISE_EASILY',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'bruiseEasily',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '6afbcb2d-7b12-4a7a-be2e-f102d98070d6',
            'yes': '86b2028c-2e3c-47f2-aff1-569dd4550a38',
        },
        apiTopicName: 'bruise-easily',
        apiTopicUuid: '16b84413-5ba9-4900-b835-95e65a78cf89',
    },
    'hiv': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SUFFER_OF_FOLLOWING_CONDITIONS_HIV',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'hiv',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'd5346295-ab5e-407e-be5c-d320739c095d',
            'yes': '02731d2d-0497-4f81-bdb6-7887b8b7108f',
        },
        apiTopicName: 'hiv',
        apiTopicUuid: 'ed13b065-f412-4d4d-8d8a-0c8ac5c99316',
    },
    'liverDisease': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SUFFER_OF_FOLLOWING_CONDITIONS_LIVER',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'liverDisease',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': 'e398a456-8e12-41b3-a287-caac92e25852',
            'yes': 'ff1babc8-f5a4-4945-a120-2541f53a25cf',
        },
        apiTopicName: 'liver-disease',
        apiTopicUuid: '13d3689e-5966-4f70-b3f0-4d29f119250c',
    },
    'liverDiseaseWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_WHICH_ONE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'liverDiseaseWhichOne',
        parentTopic: 'liverDisease',
        apiTopicName: 'liver-disease-which-one',
        apiTopicUuid: 'fdac1991-d1bd-4df0-8ef1-a57996de1b24',
    },
    'kidneyDisease': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SUFFER_OF_FOLLOWING_CONDITIONS_KIDNEY',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'kidneyDisease',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '54437e62-769f-4e04-a48b-d9e6231adb12',
            'yes': '91282269-0777-4bcb-b8da-914c8841d1ea',
        },
        apiTopicName: 'kidney-disease',
        apiTopicUuid: '9c1db13d-4854-4c50-9a0b-83b34d4723a4',
    },
    'cancer': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_CANCER',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'cancer',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '80259bdf-a390-4798-84bb-045e3db070db',
            'yes': 'fb826eb8-0c67-47ab-9c64-1a78d68bb448',
        },
        apiTopicName: 'cancer',
        apiTopicUuid: '0f7b14ce-155f-45cc-a430-4605391dfe7f',
    },
    'osteoporosisMedications': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_MEDICATION_OSTEOPOROSIS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'osteoporosisMedications',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': '1b390da2-d291-4543-99de-a01c4a6ffb5a',
            'yes': '5df1e728-5368-4d4c-a03e-ae7cf26219bd',
        },
        apiTopicName: 'osteoporosis-medications',
        apiTopicUuid: '64be1a63-9698-4586-ac23-ba6e49740ec1',
    },
    'bloodThinningMedications': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_MEDICATION_BLOOD_THINNING',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'bloodThinningMedications',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': '1087ffad-0558-4787-b93e-6176b0d448b3',
            'yes': 'b1b4f43e-d90a-4aa1-87e9-1c9cc3b63874',
        },
        apiTopicName: 'blood-thinning-medications',
        apiTopicUuid: '84d35c39-4b8d-4604-a744-533f73147bd3',
    },
    'neurologicalDisease': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SUFFER_OF_FOLLOWING_CONDITIONS_NEUROLOGICAL',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'neurologicalDisease',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': 'f569a109-aa8c-48ab-a5a5-42e047623daf',
            'yes': '140f2ee7-95c1-4824-83fe-acf2ad063f3d',
        },
        apiTopicName: 'neurological-disease',
        apiTopicUuid: '3988ca5e-fda1-4053-a486-a325813cc16d',
    },
    'neurologicalDiseaseWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_WHICH_ONE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'neurologicalDiseaseWhichOne',
        parentTopic: 'neurologicalDisease',
        apiTopicName: 'neurological-disease-which-one',
        apiTopicUuid: '48898ba7-5004-4b1e-a8d7-f6d2c75284ad',
    },
    'osteoporosis': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SUFFER_OF_FOLLOWING_CONDITIONS_OSTEOPOROSIS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'osteoporosis',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '890eff96-7603-42c5-b0d7-c8f53ea033ac',
            'yes': '6bcdd600-b1b8-414e-a9e8-1f23a262ef87',
        },
        apiTopicName: 'osteoporosis',
        apiTopicUuid: '35832d54-ffad-472c-aa22-c6932e8cb348',
    },
    'arthritis': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_ARTHRISIS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'arthritis',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'e75ed2db-5517-4e7a-b3de-df61d73b965b',
            'yes': '8436bb93-a433-478e-be15-327997c05230',
        },
        apiTopicName: 'arthritis',
        apiTopicUuid: 'd28b4422-6083-4c15-8571-afa351492494',
    },
    'otherDiseases': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_OTHER_DISEASES',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'otherDiseases',
        parentTopic: null,
        displayedFormType: null,
        responseOptionToUuid: {
            'no': '93a27557-5bba-4343-a79b-9dd7871a0838',
            'yes': '361022df-ac05-4d51-819d-34079c7f5009',
        },
        apiTopicName: 'other-diseases',
        apiTopicUuid: 'b1966cc8-d0ba-4cb3-a3a8-a0f675ea3e8b',
    },
    'otherDiseasesWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_WHICH_ONE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'otherDiseasesWhichOne',
        parentTopic: 'otherDiseases',
        apiTopicName: 'other-diseases-which-one',
        apiTopicUuid: 'fcd259ca-bda6-43b1-9441-b89e9406a597',
    },
    'radiationTherapy': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_RADIATION_THERAPY_NECK_HEAD',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'radiationTherapy',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '478875a1-334a-4d64-a925-85b291b02e2f',
            'yes': 'faaed6c9-3d18-40fa-b260-bb18fe53f759',
        },
        apiTopicName: 'radiation-therapy',
        apiTopicUuid: 'b6f56d08-ba01-45ec-80f0-52ccbb686be6',
    },
    'anyMedicines': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_ANY_MEDICINES',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'anyMedicines',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': '55b13925-92db-4c85-90bd-a29038779ae6',
            'yes': '6306033b-1b38-4abe-b5bf-0eb24d81fa56',
        },
        apiTopicName: 'any-medicines',
        apiTopicUuid: 'b4e1000d-3f54-45e7-ae55-f6d13470cd93',
    },
    'anyMedicinesWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_WHICH_ONE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'anyMedicinesWhichOne',
        parentTopic: 'anyMedicines',
        apiTopicName: 'any-medicines-which-one',
        apiTopicUuid: '7327df1a-89db-4750-9fab-9030c32b95ed',
    },
    'anyOtherMedicines': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_ANY_OTHER_MEDICINES',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'anyOtherMedicines',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': '90a6c5d1-9963-40ff-b0d4-780000ed391a',
            'yes': '7d9ee6ac-7ade-4c90-95c5-495cf3b7f8dd',
        },
        apiTopicName: 'any-other-medicines',
        apiTopicUuid: '8051079d-4020-4372-a7c3-39c07fdf6abb',
    },
    'anyOtherMedicinesWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_WHICH_ONE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'anyOtherMedicinesWhichOne',
        parentTopic: 'anyOtherMedicines',
        apiTopicName: 'any-other-medicines-which-one',
        apiTopicUuid: '1ac13e3c-cb51-4d09-b071-ba3b748dc245',
    },
    'antiDepressionMedications': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_ANTIDEPRESSION_MEDICATIONS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'antiDepressionMedications',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': 'a33b5211-fad0-47be-bb21-ff4f197260b9',
            'yes': 'b5b30b9c-fa61-4f15-bf8b-146af778e481',
        },
        apiTopicName: 'anti-depression-medications',
        apiTopicUuid: '042064ef-af27-402e-a0ec-c8dfb9e1d19c',
    },
    'antiDepressionMedicationsWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_WHICH_ONE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'antiDepressionMedicationsWhichOne',
        parentTopic: 'antiDepressionMedications',
        apiTopicName: 'anti-depression-medications-which-one',
        apiTopicUuid: 'ebe998aa-2163-42a5-aad7-477627cdb4a4',
    },
    'pacemaker': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_PACEMAKER',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'pacemaker',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '4efadec8-847b-4d4d-89f9-ef875c7b9f5e',
            'yes': '93690d33-7d35-4bbb-b5b1-ba9d00a0b94e',
        },
        apiTopicName: 'pacemaker',
        apiTopicUuid: '96b20961-e1b0-4415-b37a-de28f5562052',
    },
    'bifosfonates': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_TREATED_WITH_BISFOSFONATES',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'bifosfonates',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'ef000ed1-11d2-4c25-b5cf-3bfbce6b6084',
            'yes': '527f2157-7e51-4682-9a57-3132329118a9',
        },
        apiTopicName: 'bifosfonates',
        apiTopicUuid: '841ea974-8c7f-46fe-af3e-d686adbe0607',
    },
    'anesthesia': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_ANESTHESIA_COMPLICATIONS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'anesthesia',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'c680e667-e3fa-4247-97e3-47689808f715',
            'yes': '584a1ba2-2cd9-4bb7-9475-258be0205d8c',
        },
        apiTopicName: 'anesthesia',
        apiTopicUuid: '139002dc-2905-4f4e-98d7-e62979660e98',
    },
    'hospitalized': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_HOSPITALIZED',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'hospitalized',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': 'cc769ddd-ba37-4931-9155-296bef47f439',
            'yes': 'a43d16e3-d9cc-49b0-aa2f-34dee323e768',
        },
        apiTopicName: 'hospitalized',
        apiTopicUuid: '6a5b240c-59d6-4930-b900-55fe19a29080',
    },
    'hospitalizedWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_WHICH_ONE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'hospitalizedWhichOne',
        parentTopic: 'hospitalized',
        apiTopicName: 'hospitalized-which-one',
        apiTopicUuid: '5638b662-325f-405f-a798-501a0b81d076',
    },
    'localAnesthesiaIssues': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_LOCAL_ANESTHESIA_ISSUES',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'localAnesthesiaIssues',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '675f9744-eccb-4fe5-b778-fa6438f56579',
            'yes': '60a1ac5a-6d72-4bf2-99e0-33da268470ba',
        },
        apiTopicName: 'local-anesthesia-issues',
        apiTopicUuid: '9a236f32-a4b2-4956-a2e8-b7261b98420d',
    },
    'sourceOfDiscoveringClinic': {
        question: 'QUESTION_RELATED_TO_SOURCE_OF_DISCOVERING_CLINIC',
        value: '',
        inputType: 'choice',
        responseOptions: UserHowIsClinicFoundOptionList,
        internalTopic: 'sourceOfDiscoveringClinic',
        parentTopic: null,
        apiTopicName: 'source-of-discovering-clinic',
        apiTopicUuid: '5b373bd2-15bf-4ef8-9e6f-8cb39940c49c',
    },
    'sourceOfDiscoveringClinicSecondary': {
        question: 'QUESTION_RELATED_TO_SOURCE_OF_DISCOVERING_CLINIC_SECONDARY',
        value: '',
        inputType: 'choice',
        responseOptions: UserHowIsClinicFoundOptionList,
        internalTopic: 'sourceOfDiscoveringClinicSecondary',
        parentTopic: null,
        apiTopicName: 'source-of-discovering-clinic-secondary',
        apiTopicUuid: 'e721594c-01c6-491d-9981-f9930fef0a90',
    },
    'dentistLastTimeVisited': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_LAST_TIME_DENTIST_VISITED',
        value: '',
        inputType: 'choice',
        responseOptions: DentistLastTimeVisitedOptionsList,
        internalTopic: 'dentistLastTimeVisited',
        parentTopic: null,
        apiTopicName: 'dentist-last-time-visited',
        apiTopicUuid: 'af4d995a-b580-43ba-a07c-6131813bfd92',
    },
    'dentistRegularity': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_HOW_OFTEN_GO_TO_DENTIST',
        value: '',
        inputType: 'choice',
        responseOptions: DentistRegularityOptionsList,
        internalTopic: 'dentistRegularity',
        parentTopic: null,
        apiTopicName: 'dentist-regularity',
        apiTopicUuid: 'fb3ca1b6-add2-4513-bbda-91edabaaf09a',
    },
    'brushingRegularity': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_HOW_OFTEN_BRUSHING_TEETH',
        value: '',
        inputType: 'choice',
        responseOptions: BrushingRegularityOptionsList,
        internalTopic: 'brushingRegularity',
        parentTopic: null,
        apiTopicName: 'brushing-regularity',
        apiTopicUuid: 'eebeea77-4852-4409-a607-e500ab13e4e2',
    },
    'cleaningBetweenTeethRegularity': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_HOW_OFTEN_CLEAN_BETWEEN_TEETH',
        value: '',
        inputType: 'choice',
        responseOptions: CleaningBetweenTeethRegularityOptionsList,
        internalTopic: 'cleaningBetweenTeethRegularity',
        parentTopic: null,
        apiTopicName: 'cleaning-between-teeth-regularity',
        apiTopicUuid: '3c4e5748-d138-4b5d-a85d-0d072c04a585',
    },
    'cleaningBetweenTeethRegularityWithWhat': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_HOW_OFTEN_CLEAN_BETWEEN_TEETH_WITH_WHAT',
        value: '',
        inputType: 'choice',
        responseOptions: CleaningBetweenTeethWithWhatOptionsList,
        internalTopic: 'cleaningBetweenTeethRegularityWithWhat',
        parentTopic: null,
        apiTopicName: 'cleaning-between-teeth-regularity-with-what',
        apiTopicUuid: '16c5a9a8-1890-45bd-b475-98e0ede1576c',
    },
    'dentalAnxiety': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_DENTAL_ANXIETY',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'dentalAnxiety',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': 'd024607a-3e1e-44f6-9ddf-6301fc6e7f93',
            'yes': 'ae7fa8f2-7744-46f1-a31e-f7307e55093e',
        },
        apiTopicName: 'dental-anxiety',
        apiTopicUuid: '21b1d5b5-3cba-4adf-98bf-c1a35a564837',
    },
    'dentalAnxietyWhichOne': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_DENTAL_ANXIETY_SCALE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'dentalAnxietyWhichOne',
        parentTopic: 'dentalAnxiety',
        apiTopicName: 'dental-anxiety-which-one',
        apiTopicUuid: 'a00fac15-de8f-4463-8013-70835274100b',
    },
    'unpleasantExperience': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_UNPLEASANT_EXPERIENCES_WITH_DENTIST',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'unpleasantExperience',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '888c6e1f-6e4e-4adf-84b4-869fab512f6a',
            'yes': '3464e517-77c8-44ab-83de-73ff2b7d73e2',
        },
        apiTopicName: 'unpleasant-experience',
        apiTopicUuid: '814f2c4e-f3dd-453d-881a-185a17b3ecd2',
    },
    'dissapointmentWithDentalWork': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_DISAPPOINTMENT_WITH_DENTAL_WORK',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'dissapointmentWithDentalWork',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '3db75e44-3dc8-4323-9732-ce4727b0be5e',
            'yes': '6c656896-1bf5-4c05-9f9b-b1c26ea65bfb',
        },
        apiTopicName: 'dissapointment-with-dental-work',
        apiTopicUuid: '636bcd27-f344-44dc-a040-066648d743fc',
    },
    'braces': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_BRACES_OR_BITE_ADJUSTED',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'braces',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'e5d67a6a-8509-4e5b-8f96-4cf4dcfac6ca',
            'yes': 'd590555d-8c44-4250-b1b7-6a96c4bb8ebf',
        },
        apiTopicName: 'braces',
        apiTopicUuid: '1df8cdd8-8b53-4391-a84d-5609ef8b87d4',
    },
    'previousOrthodonticTreatment': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_PREVIOUS_ORTHODONTIC_TREATMENT',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'previousOrthodonticTreatment',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'd0acc09f-d274-40ab-a747-f29ab20e0a0f',
            'yes': '6c7e1ebf-4534-4014-a81c-1bfe0ac674f1',
        },
        apiTopicName: 'previous-orthodontic-treatment',
        apiTopicUuid: '18bf4147-76b9-4d78-869c-3a437dbd59c4',
    },
    'removedTeeth': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_REMOVED_TEETH',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'removedTeeth',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'd012a6a2-d303-4992-bc1a-80701263f65f',
            'yes': '75d36d10-1075-455d-b25b-d805f50fb9de',
        },
        apiTopicName: 'removed-teeth',
        apiTopicUuid: '5bb44f4f-3ce9-4fa5-a4a1-305fff8752c3',
    },
    'pregnant': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_PREGNANT',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'pregnant',
        parentTopic: null,
        responseOptionToUuid: {
            'no': 'f0affa2b-a395-49d0-853b-c199b002f236',
            'yes': '01be719e-068b-4317-bb9b-c71550fbb2a5',
        },
        apiTopicName: 'pregnant',
        apiTopicUuid: 'c9a41701-ef44-45cc-b67a-359c3186e084',
    },
    'pregnantWhichOne': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_PREGNANT_WEEKS',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'pregnantWhichOne',
        parentTopic: 'pregnant',
        apiTopicName: 'pregnant-which-one',
        apiTopicUuid: 'c12ecaa0-cccd-4a42-b3e9-8f7b2e6c7e2c',
    },
    'wishToChangeSmile': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_WISH_TO_CHANGE_SMILE',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'wishToChangeSmile',
        parentTopic: null,
        responseOptionToUuid: {
            'no': 'bb66fd09-be23-4379-b2d6-1148ab505c45',
            'yes': '3d16e2ae-0d65-4a4f-8904-6e6f7febd80e',
        },
        apiTopicName: 'wish-to-change-smile',
        apiTopicUuid: 'efa99267-426a-4237-b41e-2918bdeb89e5',
    },
    'wishToChangeSmileWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_ELABARORATE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'wishToChangeSmileWhichOne',
        parentTopic: 'wishToChangeSmile',
        apiTopicName: 'wish-to-change-smile-which-one',
        apiTopicUuid: 'b56cb922-57ad-4f1e-8424-d76a84122693',
    },
    'teethWhitening': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_TEETH_WHITENING',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'teethWhitening',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'decb9955-d691-4cf4-94de-dd8d7eda6f1d',
            'yes': 'bf67cc72-957f-4c3b-aef9-f3d7d7031cef',
        },
        apiTopicName: 'teeth-whitening',
        apiTopicUuid: '148a7e0a-1091-4dc2-a56e-c30d74f1d9e2',
    },
    'badTimeWithSmile': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_BAD_TIME_WITH_SMILE',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'badTimeWithSmile',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '79eb8bd9-5ff2-48a4-b646-e30a1b9c71bc',
            'yes': 'a718e1c4-861c-4d4f-af50-668a87690484',
        },
        apiTopicName: 'bad-time-with-smile',
        apiTopicUuid: 'b2b5c06b-b0da-4758-bd35-b240e6d32f47',
    },
    'problemsChewingGum': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_PROBLEMS_CHEWING_GUM',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'problemsChewingGum',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '687efe69-11a4-4ebe-8c68-225570a8c57a',
            'yes': 'b7facdf0-bde6-4805-9f41-300f0c601ea3',
        },
        apiTopicName: 'problems-chewing-gum',
        apiTopicUuid: '657e298e-f210-48e7-a6e1-765ea6393904',
    },
    'problemsChewingHardFood': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_CHEWING_HARD_FOOD',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'problemsChewingHardFood',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '68f35243-76f5-4106-9811-beb4331bb886',
            'yes': '340ee36d-ea07-4184-9840-05bee63d97f5',
        },
        apiTopicName: 'problems-chewing-hard-food',
        apiTopicUuid: '4b458942-6d38-4876-93be-4bf569981256',
    },
    'moreThanOneBiteFit': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_MORE_THAN_ONE_BITE_FIT',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'moreThanOneBiteFit',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '66cb41e1-5aec-4db0-b29b-e30efc969ee2',
            'yes': 'e374a043-175e-4181-a1fb-72d13081ed4f',
        },
        apiTopicName: 'more-than-one-bite-fit',
        apiTopicUuid: 'a867f869-84a1-4c0e-bb8f-ca023b71c3f2',
    },
    'bitingNails': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_BITING_NAILS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'bitingNails',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '85e5c5b1-2c93-4cff-b5e7-ad4707c52582',
            'yes': '49c244b1-7236-4302-9ba3-a756899900f5',
        },
        apiTopicName: 'biting-nails',
        apiTopicUuid: '6d8bb0ef-b4a2-4c4b-906c-ead8c25f6522',
    },
    'wakeupChangedFeelingInTeeth': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_WAKEUP_CHANGED_FEELING_IN_TEETH',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'wakeupChangedFeelingInTeeth',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        apiTopicName: 'wakeup-changed-feeling-in-teeth',
        apiTopicUuid: '761b06e4-0d2a-44bd-a304-0ea6194ea8df',
        responseOptionToUuid: null, // NOTE: Was just not created by mistake
    },
    'teethGrinding': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_TEETH_GRINDING',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'teethGrinding',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'a78b2fff-1109-4022-afe8-396bcd6214c8',
            'yes': 'ba53e0b5-f6c1-4553-a498-0031f7faa6ed',
        },
        apiTopicName: 'teeth-grinding',
        apiTopicUuid: 'fe86a513-1f3e-4dcf-83c8-7767a77ebafc',
    },
    'jawJointPain': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_JAW_JOINT_PAIN',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'jawJointPain',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'c2083689-019a-4446-bb30-7c72637824b1',
            'yes': '9243ae6d-d812-4ca2-a7a8-64981d5169b6',
        },
        apiTopicName: 'jaw-joint-pain',
        apiTopicUuid: '49914419-0393-4a81-a194-2eb8645eaea6',
    },
    'painInMasticatoryMuscles': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_PAIN_IN_MASTICATORY_MUSCLES',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'painInMasticatoryMuscles',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'cddb9221-2874-478c-a7d9-c3998cd96300',
            'yes': '660cef0e-fdc2-4071-8307-ecfe2771bb69',
        },
        apiTopicName: 'pain-in-masticatory-muscles',
        apiTopicUuid: 'e270d9b7-adb1-4956-a6b8-e7c78145973b',
    },
    'headNeckAndShoulderPain': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_HEADACHES_OR_NECK_SHOULDER_PAIN',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'headNeckAndShoulderPain',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '6765eff4-d1b1-458b-b13a-25a6712f3985',
            'yes': 'ec7d6e0b-8997-4464-82da-48ac675bf3eb',
        },
        apiTopicName: 'head-neck-and-shoulder-pain',
        apiTopicUuid: '862eb4c3-e8a0-4a0c-a17d-31b6747eff62',
    },
    'biteSplint': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_BITE_SPLINT',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'biteSplint',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'fa8fe464-fc02-4564-9323-48aa24c18a8b',
            'yes': '83269cf3-4799-4562-b803-dfeb887cf161',
        },
        apiTopicName: 'bite-splint',
        apiTopicUuid: '49513aed-c172-4482-80bd-7774c2b76de2',
    },
    'changedTeethDuringLastFiveYears': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_CHANGED_TEETH_DURING_LAST_5_YEARS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'changedTeethDuringLastFiveYears',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'e85d2782-374b-43db-babb-058c25a8d290',
            'yes': '336f3f8a-82f3-4ced-8a36-61f7a1a26e93',
        },
        apiTopicName: 'changed-teeth-during-last-five-years',
        apiTopicUuid: '22bff0e9-6f22-4c1f-8304-2ca7384cec11',
    },
    'teethSpacingEnhancedOrDecreased': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_TEETH_SPACING_ENHANCED_OR_DECREASED',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'teethSpacingEnhancedOrDecreased',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '8f230c7b-84de-4b87-9848-53764ec616d3',
            'yes': '099b1ebe-87bc-4511-b8b1-35187cc0e7d2',
        },
        apiTopicName: 'teeth-spacing-enhanced-or-decreased',
        apiTopicUuid: 'c107ddac-8014-41e2-be88-84893465eac5',
    },
    'cavities': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_CAVITIES',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'cavities',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '2f7a3488-1057-491b-9e6b-c691c17a731f',
            'yes': 'f04e9a82-a67f-4861-a817-d8f19214799d',
        },
        apiTopicName: 'cavities',
        apiTopicUuid: 'a163871c-f226-4f43-81b2-cef2b8342fc5',
    },
    'dryMouth': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_DRY_MOUTH',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'dryMouth',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '50038091-2574-4325-9c05-32444c1a9835',
            'yes': 'cce1fae1-fbca-49a7-a190-fb67ee73b1a2',
        },
        apiTopicName: 'dry-mouth',
        apiTopicUuid: '1d027785-1c16-49c4-9e75-7a8eb09f915a',
    },
    'teethSensitivity': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SENSITIVITY_TO_TEMP',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'teethSensitivity',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'd2f55de1-5502-4424-8c80-fa034c776165',
            'yes': '58af2f8b-b635-49b7-9d74-f134c300a2ab',
        },
        apiTopicName: 'teeth-sensitivity',
        apiTopicUuid: 'bf3f77e5-9e47-4d4e-ba39-3e15016b7e09',
    },
    'brokenTeeth': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_BROKEN_TEETH',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'brokenTeeth',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'dc5cf476-d3a3-45a1-945c-a829bb3994b0',
            'yes': 'df9e15d6-30f9-4bd3-be28-0497ed8a3910',
        },
        apiTopicName: 'broken-teeth',
        apiTopicUuid: '36099dd2-edb5-4496-a4be-f009acdd0e4c',
    },
    'stuckFoodEating': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_STUCK_FOOD_EATING',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'stuckFoodEating',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '693c3c08-84b3-41cd-9cb5-350f104af7b2',
            'yes': '011877dd-64a6-4e00-9202-28b72fdb0616',
        },
        apiTopicName: 'stuck-food-eating',
        apiTopicUuid: 'ca309936-5a79-4fe2-ab8d-ccb3676fa0b3',
    },
    'bleedingGums': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_BLEEDING_GUMS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'bleedingGums',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'fd0e320f-a7bc-4b0d-8650-7845e5439951',
            'yes': 'f976220e-d6b5-4e36-a439-8040bd75b02c',
        },
        apiTopicName: 'bleeding-gums',
        apiTopicUuid: '5f134950-0ce5-4ca5-be07-532e7f708dad',
    },
    'gumsContact': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_GUMS_CONTACT',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'gumsContact',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '9ebfc538-7df8-4b98-83f0-7323dc3b1da4',
            'yes': '1f5056c6-f8ef-4a82-8cb7-301bf61293a4',
        },
        apiTopicName: 'gums-contact',
        apiTopicUuid: 'f4801d97-0c3d-47e9-81ed-329a00d7dd09',
    },
    'looseTeeth': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_LOOSE_TEETH',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'looseTeeth',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'a62b3973-ff48-4f55-ad5f-d9bb6dc82a0a',
            'yes': '2b353820-f091-45a9-a537-bb89a132f856',
        },
        apiTopicName: 'loose-teeth',
        apiTopicUuid: '6ca4dbac-1ce8-44d0-87e7-1f43bc538c73',
    },
    'mouthOdor': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_MOUTH_ODOR',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'mouthOdor',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'e54e99ef-d1d2-4569-8b2f-e2776cfecbd1',
            'yes': '339cf94a-9592-4794-8b48-a9ea81e2d608',
        },
        apiTopicName: 'mouth-odor',
        apiTopicUuid: '6d455a55-cbf8-4910-9181-2209928d700e',
    },
    'burningSensation': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_BURNING_SENSATION',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'burningSensation',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '56595dd0-99ce-4fcc-b97e-4226ad5ed5e7',
            'yes': '91d95f0f-6e4e-47aa-9b79-889afc8aad67',
        },
        apiTopicName: 'burning-sensation',
        apiTopicUuid: '23b69ef5-d842-4020-8fbd-a72cc2f0fae7',
    },
    'treatedForGumDisease': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_TREATED_FOR_GUM_DESEASE',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'treatedForGumDisease',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '74cb92b3-96b1-4c93-b1f4-7ec027637c91',
            'yes': '687daab9-798b-4892-be53-c9a8dbae2e9d',
        },
        apiTopicName: 'treated-for-gum-disease',
        apiTopicUuid: '704a8d23-7ae2-4fae-941d-c4f40178d343',
    },
    'familyMembersWithPeriodontitis': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_FAMILY_MEMBERS_WITH_PERIODONTIS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'familyMembersWithPeriodontitis',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '8ad8c62d-75f4-4cab-88fd-84fe71a66378',
            'yes': 'cd1be30a-ee4f-4a39-86ec-b013a41c8287',
        },
        apiTopicName: 'family-members-with-periodontitis',
        apiTopicUuid: '1bb1e88b-66b8-4a16-bd84-42706192b468',
    },
    'teethSensitiveToHeat': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_TEETH_SENSITIVE_TO_HEAT',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'teethSensitiveToHeat',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'a8911343-25f9-4cdf-955c-5af11fcfe5e2',
            'yes': '7345e5df-9ca9-4e5f-8ec7-66500fd12ea3',
        },
        apiTopicName: 'teeth-sensitive-to-heat',
        apiTopicUuid: '30aaa88a-a5da-4c91-9552-1468c7084a9d',
    },
    'teethSensitiveToCold': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_TEETH_SENSITIVE_TO_COLD',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'teethSensitiveToCold',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '9e2a5f86-f2bc-4780-b7be-f3ed0ab2da4a',
            'yes': 'b04505eb-bf0d-4078-a07d-beac299573b7',
        },
        apiTopicName: 'teeth-sensitive-to-cold',
        apiTopicUuid: 'aa1eceb4-f52e-4e84-b3d9-a5bdad0c9e7d',
    },
    'teethSensitiveToSpices': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_TEETH_SENSITIVE_TO_SPICES',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'teethSensitiveToSpices',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'b18724d5-d0fc-43c6-b3cc-9f5ddb628598',
            'yes': 'c194a8c3-0b92-404a-9aae-f8b21b8cbe14',
        },
        apiTopicName: 'teeth-sensitive-to-spices',
        apiTopicUuid: 'b173f4c2-ff63-4f3b-b67c-0d79640598ae',
    },
    'teethSensitiveToChewing': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_TEETH_SENSITIVE_TO_CHEWING',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'teethSensitiveToChewing',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '893a0995-ca7d-4036-9ab2-6e2f91e3e169',
            'yes': '97ae2257-9cc7-4121-9b13-f597f7e42d51',
        },
        apiTopicName: 'teeth-sensitive-to-chewing',
        apiTopicUuid: '3c48ddb7-7984-416f-ab77-b30e48f9b6bd',
    },
    'avoidBrushingSomeTeeth': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_AVOID_BRUSHING_SOME_TEETH',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'avoidBrushingSomeTeeth',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeText',
        responseOptionToUuid: {
            'no': '2ff3ab76-b031-4336-900a-37aafc678045',
            'yes': '7a03f4c8-c522-4581-9523-4b9d66ca3fea',
        },
        apiTopicName: 'avoid-brushing-some-teeth',
        apiTopicUuid: '6e3d341d-6f7f-42ab-a492-b53a4cdc215a',
    },
    'avoidBrushingSomeTeethWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_WHICH_ONE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'avoidBrushingSomeTeethWhichOne',
        parentTopic: 'avoidBrushingSomeTeeth',
        apiTopicName: 'avoid-brushing-some-teeth-which-one',
        apiTopicUuid: '24f54f8c-ab9d-41ff-ab0a-1d59c8f343a7',
    },
    'swellingGums': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SWELLING_GUMS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'swellingGums',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '960c416e-055b-4fce-9899-ab889e5bc239',
            'yes': '7fc6e86e-5527-458c-8e8a-ed508a6b1b53',
        },
        apiTopicName: 'swelling-gums',
        apiTopicUuid: '3483560f-1a64-452c-b808-9ba63ae86db2',
    },
    'jawExperiencePainOrIrritation': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_EXPERIENCE_JAW_HURTS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'jawExperiencePainOrIrritation',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'da9b1e19-d23f-4db5-ab3d-036f9cc85a44',
            'yes': '62a4e418-871b-4371-9148-7beac574ffaf',
        },
        apiTopicName: 'jaw-experience-pain-or-irritation',
        apiTopicUuid: 'ad8fc251-ef57-4f3e-ac1b-362e41be7ae2',
    },
    'jawExperienceHeadache': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_EXPERIENCE_HEADACHE_DUE_TO_JAW',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'jawExperienceHeadache',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '4cd5bfe1-9cf0-4ebd-9c06-c01c9f3baa04',
            'yes': '928d96ff-548f-4930-9621-8efc1e8aecef',
        },
        apiTopicName: 'jaw-experience-headache',
        apiTopicUuid: '29c8d656-30f5-49fc-9f8f-e94db13f4f01',
    },
    'snoreOrWakeAtNight': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SNORE_AND_WAKE_AT_NIGHT',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'snoreOrWakeAtNight',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'c7a173fe-df8d-4eec-9d1c-71fb11c8765f',
            'yes': '03ac6b4b-4558-4ce8-a6c7-8d026880b670',
        },
        apiTopicName: 'snore-or-wake-at-night',
        apiTopicUuid: '95d1fa7a-eb9d-4702-a73f-f94bd14ab33c',
    },
    'jawMakesSounds': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_JAW_MAKES_SOUNDS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'jawMakesSounds',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '560e5619-7c50-4a9e-a779-bfc78af10a9c',
            'yes': '73042d56-4e05-4391-9985-9b46bcf99b9a',
        },
        apiTopicName: 'jaw-makes-sounds',
        apiTopicUuid: 'db9cb28e-e9c9-433f-9c7c-081adff00f5b',
    },
    'jawHurts': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_JAW_HURTS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'jawHurts',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '41581941-b11c-4a62-9157-3d22ae725a1a',
            'yes': '670a6e29-f1c4-41b8-a9e5-61b3fa5c2cee',
        },
        apiTopicName: 'jaw-hurts',
        apiTopicUuid: 'f3d80b36-6df3-458f-8c44-a7543f40cad4',
    },
    'jawHardToOpenClose': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_JAW_HARD_TO_OPEN_CLOSE',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'jawHardToOpenClose',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '0c5e3c54-4aa0-404c-b158-5bd107cac78d',
            'yes': '2cc1ba36-552f-40d8-9d8a-fe9b593aaee1',
        },
        apiTopicName: 'jaw-hard-to-open-close',
        apiTopicUuid: '17e8196f-6485-4978-99be-c6977ed8bb8c',
    },
    'jawHardTimeChewing': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_JAW_HARD_TIME_CHEWING',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'jawHardTimeChewing',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'a592ee93-4930-4909-b918-963e58785f87',
            'yes': '5749192e-348a-4d95-91d9-d228ff3b93f1',
        },
        apiTopicName: 'jaw-hard-time-chewing',
        apiTopicUuid: 'fa044869-5716-411e-8296-a91321ace5dc',
    },
    'concernedOfCosts': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_CONCERNED_OF_COSTS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'concernedOfCosts',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeTextLong',
        responseOptionToUuid: {
            'no': 'fc8401c4-e9f6-4979-a779-3c62f8aa17b6',
            'yes': 'c093cf6d-339f-40ac-a1b8-45866df99dab',
        },
        apiTopicName: 'concerned-of-costs',
        apiTopicUuid: '0e531951-3040-4ab2-8b46-751fdda5a929',
    },
    'concernedOfCostsWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_ELABARORATE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'concernedOfCostsWhichOne',
        parentTopic: 'concernedOfCosts',
        apiTopicName: 'concerned-of-costs-which-one',
        apiTopicUuid: '4ea8430b-de0e-4c97-af23-92e500ea113a',
    },
    'concernedAboutDentalTreatment': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_CONCERNED_ABOUT_DENTAL_TREATMENT',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'concernedAboutDentalTreatment',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeTextLong',
        responseOptionToUuid: {
            'no': '662eecfd-8b13-49d5-819b-00db30dd9da4',
            'yes': '3c13daf5-26c7-44a3-ba82-9c8924083d9e',
        },
        apiTopicName: 'concerned-about-dental-treatment',
        apiTopicUuid: 'f1a469db-4056-4cd4-93e8-c0b00104b701',
    },
    'concernedAboutDentalTreatmentWhichOne': {
        question: 'QUESTION_GENERIC_IF_SO_ELABARORATE',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'concernedAboutDentalTreatmentWhichOne',
        parentTopic: 'concernedAboutDentalTreatment',
        apiTopicName: 'concerned-about-dental-treatment-which-one',
        apiTopicUuid: 'c233ef93-c6f7-4223-8832-f4c82573b9fc',
    },
    'wouldAcceptProstethis': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_WOULD_ACCEPT_PROSTETHIS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'wouldAcceptProstethis',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'b1ae5995-c903-4e3f-a230-1c283f36ab1e',
            'yes': 'a2bc0fd4-51f7-43c7-b7e5-6dcc03ec5889',
        },
        apiTopicName: 'would-accept-prostethis',
        apiTopicUuid: 'c6214bf6-741c-4bd7-9ae7-93efa1c39790',
    },
    'stomachUlcer': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_STOMACH_ULCER',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'stomachUlcer',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '6af92895-6e97-4872-bad3-34f19361e736',
            'yes': '92e079ed-a944-4f37-bc95-df056a40240a',
        },
        apiTopicName: 'stomach-ulcer',
        apiTopicUuid: '274ecf37-90b7-46d0-8f8f-5526be077c91',
    },
    'covid19TestedPositive': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_COVID19_TESTED_POSITIVE',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'covid19TestedPositive',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '346732e4-6a0c-41ad-878f-ae165acdfd64',
            'yes': '7be81240-b6e1-4aea-8847-d2325fe52a34',
        },
        apiTopicName: 'covid19-tested-positive',
        apiTopicUuid: 'edd85b8d-e44e-4614-aab4-644e30364b26',
    },
    'covid19RaisedTemperature': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_COVID19_RAISED_TEMPERATURE',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'covid19RaisedTemperature',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '56fb24a9-1afb-41b6-810e-7d4675775711',
            'yes': '775c5827-b8dd-4372-8e3a-cc98691e46a4',
        },
        apiTopicName: 'covid19-raised-temperature',
        apiTopicUuid: '7d2ff65a-48d2-4c6a-8a00-f04047af0d43',
    },
    'covid19ContinuousCough': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_COVID19_CONTINUOUS_COUGH',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'covid19ContinuousCough',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'cdc80120-f511-4ea0-87ab-b3fd1c66e160',
            'yes': '5deaa927-c793-4862-961c-24a10797fefd',
        },
        apiTopicName: 'covid19-continuous-cough',
        apiTopicUuid: 'a59653d4-7ba6-4bc7-94ce-8a9790275d69',
    },
    'covid19LossOfSmellOrTaste': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_COVID19_LOSS_OF_SMELL_OR_TASTE',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'covid19LossOfSmellOrTaste',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '41d174df-3e42-4de9-b4ad-5b51fbe67bf4',
            'yes': '82719db2-ed93-4d1e-a2da-fedf206026e1',
        },
        apiTopicName: 'covid19-loss-of-smell-or-taste',
        apiTopicUuid: 'c41c1f7d-50f4-4cb8-866b-ec0cf99cf39b',
    },
    'covid19RecentIsolation': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_COVID19_RECENT_ISOLATION',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'covid19RecentIsolation',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '9dbee3e6-6fea-4e23-b56a-72a397772ce8',
            'yes': '7fe09661-cddb-4091-a4be-b3e616370519',
        },
        apiTopicName: 'covid19-recent-isolation',
        apiTopicUuid: '32a84510-1b39-4401-b4f9-c69d3f7324b3',
    },
    'covid19HouseholdHadSymptoms': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_COVID19_HOUSEHOLD_HAD_FLU_SYMPTOMS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'covid19HouseholdHadSymptoms',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '9cd4c4ae-8ca0-4949-8c52-c0e3b61c68db',
            'yes': '58cfc9f3-b5f2-463e-9855-5cd396dcf729',
        },
        apiTopicName: 'covid19-household-had-symptoms',
        apiTopicUuid: '913318b8-abff-4f4d-acbd-dabde05de511',
    },
    'covid19UnderlyingHealthCondition': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_COVID19_UNDERLYING_HEALTH_CONDITION',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'covid19UnderlyingHealthCondition',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '749bddf7-d25f-4799-a5f0-5c2d5ae88b5c',
            'yes': 'f168296b-21db-4183-a023-cf02e65081ff',
        },
        apiTopicName: 'covid19-underlying-health-condition',
        apiTopicUuid: '5daa8426-5ab4-4b72-93f3-f7f89698dd08',
    },
    'covid19FromHighEffectedPopulationGroup': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_COVID19_FROM_HIGH_AFFECTED_POPULATON_GROUP',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'covid19FromHighEffectedPopulationGroup',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'b015148e-aa16-4a7d-80a2-0754de1732a4',
            'yes': '1b5c5876-6a6f-4ac2-92ee-77497e7bd630',
        },
        apiTopicName: 'covid19-from-high-effected-population-group',
        apiTopicUuid: '41dbeb9f-aeb6-4101-98f6-e9a6e092487a',
    },
    'spleenIssues': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SPLEEN_ISSUES',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'spleenIssues',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '79c58b87-7845-4c36-9287-767b20c52a7a',
            'yes': 'a36c135b-92c5-40b3-9ce4-e549172acb44',
        },
        apiTopicName: 'spleen-issues',
        apiTopicUuid: 'c2b5d904-ccda-4509-9f9e-c206904e8dda',
    },
    'bloodTests': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_HAD_BLOOD_TESTS_OR_INOCULATIONS',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'bloodTests',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'faf8307d-c42f-4113-9310-11748ee8d9e0',
            'yes': 'd0b5d215-350b-43a4-b6de-d0e91b325b09',
        },
        apiTopicName: 'blood-tests',
        apiTopicUuid: 'c86d0508-c8c8-4b6b-8d51-cf88052bde9e',
    },
    'bloodTransfusionBeenRefused': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_HAS_BLOOD_TRANSFUSION_BEEN_REFUSED',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'bloodTransfusionBeenRefused',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'a860e44f-2230-41f7-97c4-4b9757793135',
            'yes': '7634aeef-5512-4c14-bbec-0c4458dadd43',
        },
        apiTopicName: 'blood-transfusion-been-refused',
        apiTopicUuid: 'b2fde8b3-f640-4b6a-80fc-a628071e3618',
    },
    'currentMedicalAttention': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_CURRENTLY_IN_MEDICAL_ATTENTION',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'currentMedicalAttention',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '8a8d9f88-f854-40f8-8d4a-88835f573c32',
            'yes': '58c6185d-2d43-4d63-bfab-d0a3f5678234',
        },
        apiTopicName: 'current-medical-attention',
        apiTopicUuid: 'f61e7517-b1d7-4b06-a41d-e03ff693a573',
    },
    'jointReplacement': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_JOINT_REPLACEMENT',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'jointReplacement',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'f1387d25-e822-414b-ba61-67a211b8c3a3',
            'yes': 'a4797d0a-e252-488f-9197-2314e8633efb',
        },
        apiTopicName: 'joint-replacement',
        apiTopicUuid: '4818296f-8f56-47e1-b6cc-241468bf17ca',
    },
    'carryMedicalWarningCard': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_CARRY_MEDICAL_WARNING_CARD',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'carryMedicalWarningCard',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '8d429670-2f5f-4da6-9de2-bcfc9e7c98be',
            'yes': '594c43de-0d74-4179-87b7-e8292b7740ae',
        },
        apiTopicName: 'carry-medical-warning-card',
        apiTopicUuid: '22e4c04e-97d7-40ec-9f76-d5b04c22e171',
    },
    'coldSores': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_COLD_SORES',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'coldSores',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '90a10085-dc44-48f8-9b92-285479984924',
            'yes': '8e3f3550-5a87-456a-9064-fad1656c0ca6',
        },
        apiTopicName: 'cold-sores',
        apiTopicUuid: 'cfcf8bd7-2cf9-48e3-9d00-8c010ac0011c',
    },
    'growthHormoneTreatment': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_GROWTH_HORMONE_TREATMENT_AS_CHILD',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'growthHormoneTreatment',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '6b42add8-9a15-4402-8a49-c74d97b8458f',
            'yes': 'f96ab659-2409-437b-9031-a59321b09c37',
        },
        apiTopicName: 'growth-hormone-treatment',
        apiTopicUuid: '9dbfa1ac-d56e-407d-90ec-87d06d98df21',
    },
    'creutzfeldtJakobDisease': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_CREUTZFELDT_JAKOB_DISEASE_AMONG_RELATIVES',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'creutzfeldtJakobDisease',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '4f6eb9cf-aa3c-4fa9-90b6-39f69ae22665',
            'yes': '6d76c17b-21bf-44e8-ac4a-dc94b5db0316',
        },
        apiTopicName: 'creutzfeldt-jakob-disease',
        apiTopicUuid: 'c630e8f4-96c6-4e04-b59c-10c20367db5a',
    },
    'regularExcessiveAlcoholConsumption': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_REGULAR_EXCESSIVE_ALCHOHOL_CONSUMPTION',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'regularExcessiveAlcoholConsumption',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '7668bded-c4ec-46dc-9967-d2b314b2730a',
            'yes': '7d09b700-5e60-4721-a18c-f3f0a2f59286',
        },
        apiTopicName: 'regular-excessive-alcohol-consumption',
        apiTopicUuid: '3fb35452-d4ce-42a5-9507-375e1fe5a176',
    },
    'alcoholConsumption': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_ALCOHOL_CONSUMPTION',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'alcoholConsumption',
        parentTopic: null,
        apiTopicName: 'alcohol-consumption',
        apiTopicUuid: 'a3d05379-ae9a-47d2-8efc-c4a0afe0c27a',
    },
    'chewTobacco': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_CHEW_TOBACCO',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'chewTobacco',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': '3c00ca27-5ed2-46c9-9ade-b4f1de3c9941',
            'yes': '93e1159e-e4ae-4671-b886-d30d7d0f8115',
        },
        apiTopicName: 'chew-tobacco',
        apiTopicUuid: '9dc8fa03-9140-4a72-82ad-27794caf62f4',
    },
    'satifisfactionWithHealthOfTeeth': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SATISFACTION_HEALTH_OF_TEETH',
        value: '',
        inputType: 'choice',
        responseOptions: GenericSatisfationOptionsList,
        internalTopic: 'satifisfactionWithHealthOfTeeth',
        parentTopic: null,
        displayedFormType: 'genericSatisfationOptionsList',
        apiTopicName: 'satifisfaction-with-health-of-teeth',
        apiTopicUuid: 'bf0f8c91-1bea-48e5-953b-3d9cc1c7a030',
    },
    'satifisfactionWithColorOfTeeth': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SATISFACTION_COLOR_OF_TEETH',
        value: '',
        inputType: 'choice',
        responseOptions: GenericSatisfationOptionsList,
        internalTopic: 'satifisfactionWithColorOfTeeth',
        parentTopic: null,
        displayedFormType: 'genericSatisfationOptionsList',
        apiTopicName: 'satifisfaction-with-color-of-teeth',
        apiTopicUuid: '4d8214a7-5729-4299-96de-b6174fe6d3e0',
    },
    'satifisfactionWithAlignmentOfTeeth': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_SATISFACTION_ALIGNMENT_OF_TEETH',
        value: '',
        inputType: 'choice',
        responseOptions: GenericSatisfationOptionsList,
        internalTopic: 'satifisfactionWithAlignmentOfTeeth',
        parentTopic: null,
        displayedFormType: 'genericSatisfationOptionsList',
        apiTopicName: 'satifisfaction-with-alignment-of-teeth',
        apiTopicUuid: 'ca66b27c-4fae-4734-ac46-523a88782c07',
    },
    'takePhotosInClinic': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_TAKE_PHOTOS_IN_CLINIC',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'takePhotosInClinic',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'e6363219-10a2-4e04-8afa-928b195ee165',
            'yes': 'd40b1680-e632-4d23-8f93-4e17a08174db',
        },
        apiTopicName: 'take-photos-in-clinic',
        apiTopicUuid: '7d8c8bf2-86a8-4273-94cf-2734bbc060ee',
    },
    'consentToMarketing': {
        question: 'QUESTION_RELATED_TO_CONSENT_TO_MARKETING',
        value: '',
        inputType: 'choice',
        responseOptions: GenericYesNoOptionsList,
        internalTopic: 'consentToMarketing',
        parentTopic: null,
        displayedFormType: 'flatYesNoOptionsList',
        responseOptionToUuid: {
            'no': 'a5b4a6dd-ec40-4e08-9012-d93ce92e8484',
            'yes': 'c9afe126-9e6a-4895-bcc5-1d74469608d8',
        },
        apiTopicName: 'consent-to-marketing',
        apiTopicUuid: '90bdee92-e406-4f4d-8d56-d7d669b7b041',
    },
    'additionalOpenTopic': {
        question: 'QUESTION_RELATED_TO_ANAMNESIS_ADDITIONAL_OPEN_TOPIC',
        value: '',
        inputType: 'free-text',
        responseOptions: null,
        internalTopic: 'additionalOpenTopic',
        parentTopic: null,
        displayedFormType: 'nestedYesNoOptionListFreeTextLong',
        apiTopicName: 'additional-open-topic',
        apiTopicUuid: 'a7179be9-016f-491d-b458-5284d6725dac',
    },
}

export default rows
