const responseOptions = [
    {
      label: 'OPTION_DAILY_RELATED_TO_ANAMNESIS_HOW_OFTEN_CLEAN_BETWEEN_TEETH',
      value: 'daily',
    },
    {
      label: 'OPTION_SEVERAL_WEEK_RELATED_TO_ANAMNESIS_HOW_OFTEN_CLEAN_BETWEEN_TEETH',
      value: 'several-times-per-week',
    },
    {
      label: 'OPTION_WEEKLY_RELATED_TO_ANAMNESIS_HOW_OFTEN_CLEAN_BETWEEN_TEETH',
      value: 'weekly',
    },
    {
      label: 'OPTION_RARER_RELATED_TO_ANAMNESIS_HOW_OFTEN_CLEAN_BETWEEN_TEETH',
      value: 'rearer',
    },
]

export default Object.freeze(responseOptions)
