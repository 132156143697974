const responseOptions = [
    {
      label: 'USER_RESPONSE_OPTION_HIGH',
      value: 'high',
    },
    {
      label: 'USER_RESPONSE_OPTION_LOW',
      value: 'low',
    },
]

export default Object.freeze(responseOptions)
