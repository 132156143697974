const responseOptions = [
    {
      label: 'USER_RESPONSE_OPTION_GENERIC_SATISFACTION_1',
      value: 'satisfaction_very_high',
    },
    {
      label: 'USER_RESPONSE_OPTION_GENERIC_SATISFACTION_2',
      value: 'satisfaction_somewhat_high',
    },
    {
      label: 'USER_RESPONSE_OPTION_GENERIC_SATISFACTION_3',
      value: 'satisfaction_mid',
    },
    {
      label: 'USER_RESPONSE_OPTION_GENERIC_SATISFACTION_4',
      value: 'satisfaction_somewhat_low',
    },
    {
      label: 'USER_RESPONSE_OPTION_GENERIC_SATISFACTION_5',
      value: 'satisfaction_very_low',
    },
  ]

  export default Object.freeze(responseOptions)
