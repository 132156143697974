const steps = [
    {
      title: 'Landing anamnesis info (internal title)',
      index: 0,
      name: 'landing-anamnesis-info',
      questionsPage: undefined,
    },
    {
      title: 'Defaults to: Private information (internal title)',
      index: 1,
      name: 'private-information',
      questionsPage: 'page1',
    },
    {
      title: 'Defaults to: Medical History 1/3 (internal title)',
      index: 2,
      name: 'medical-history',
      questionsPage: 'page2',
    },
    {
      title: 'Defaults to: Medical History 2/3 (internal title)',
      index: 3,
      name: 'medical-history',
      questionsPage: 'page3',
    },
    {
      title: 'Defaults to: Medical History 3/3 (internal title)',
      index: 4,
      name: 'medical-history',
      questionsPage: 'page4',
    },
    {
      title: 'Defaults to: Personal History (internal title)',
      index: 5,
      name: 'personal-history',
      questionsPage: 'page5',
    },
    {
      title: 'Defaults to: Your smile (internal title)',
      index: 6,
      name: 'your-smile',
      questionsPage: 'page6',
    },
    {
      title: 'Defaults to: bite and jaw (internal title)',
      index: 7,
      name: 'bite-jaw',
      questionsPage: 'page7',
    },
    {
      title: 'Defaults to: Teeth (internal title)',
      index: 8,
      name: 'Teeth',
      questionsPage: 'page8',
    },
    {
      title: 'Defaults to: gums and bones (internal title)',
      index: 9,
      name: 'gums-bones',
      questionsPage: 'page9',
    },
    {
      title: 'Submit anamnesis (internal title)',
      index: 10,
      name: 'submit-anamnesis',
      questionsPage: undefined,
    },
    {
      title: 'Finished anamnesis (internal title)',
      index: 11,
      name: 'finish-anamnesis',
      questionsPage: undefined,
    },
  ]

export default Object.freeze(steps)
