const responseOptions = [
    {
      label: 'OPTION_THREE_PLUS_RELATED_TO_ANAMNESIS_HOW_OFTEN_BRUSHING_TEETH',
      value: 'three-plus',
    },
    {
      label: 'OPTION_TO_RELATED_TO_ANAMNESIS_HOW_OFTEN_BRUSHING_TEETH',
      value: 'two',
    },
    {
      label: 'OPTION_EN_RELATED_TO_ANAMNESIS_HOW_OFTEN_BRUSHING_TEETH',
      value: 'one',
    },
    {
      label: 'OPTION_RARER_RELATED_TO_ANAMNESIS_HOW_OFTEN_BRUSHING_TEETH',
      value: 'less_often',
    },
]

export default Object.freeze(responseOptions)
