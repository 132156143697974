const responseOptions = [
  {
    label: 'USER_RESPONSE_OPTION_SOURCE_OF_DISCOVERING_CLINIC_GOOGLE',
    value: 'google',
  },
  {
    label: 'USER_RESPONSE_OPTION_SOURCE_OF_DISCOVERING_CLINIC_FACEBOOK',
    value: 'facebook',
  },
  {
    label: 'USER_RESPONSE_OPTION_SOURCE_OF_DISCOVERING_CLINIC_WEBSITE',
    value: 'website',
  },
  {
    label: 'USER_RESPONSE_OPTION_SOURCE_OF_DISCOVERING_CLINIC_RECOMMENDED',
    value: 'recommended',
  },

  {
    label: 'USER_RESPONSE_OPTION_SOURCE_OF_DISCOVERING_CLINIC_OTHER',
    value: 'other',
  },
]

export default Object.freeze(responseOptions)
