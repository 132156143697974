const responseOptions = [
    {
      label: 'OPTION_FLOSS_RELATED_TO_ANAMNESIS_HOW_OFTEN_CLEAN_BETWEEN_TEETH_WITH_WHAT',
      value: 'floss',
    },
    {
      label: 'OPTION_BRUSH_RELATED_TO_ANAMNESIS_HOW_OFTEN_CLEAN_BETWEEN_TEETH_WITH_WHAT',
      value: 'interdental-brush',
    },
    {
      label: 'OPTION_TOOTHPICKS_RELATED_TO_ANAMNESIS_HOW_OFTEN_CLEAN_BETWEEN_TEETH_WITH_WHAT',
      value: 'toothpicks',
    },
    {
      label: 'OPTION_OTHER_RELATED_TO_ANAMNESIS_HOW_OFTEN_CLEAN_BETWEEN_TEETH_WITH_WHAT',
      value: 'other',
    },
    {
      label: 'OPTION_NONE_RELATED_TO_ANAMNESIS_HOW_OFTEN_CLEAN_BETWEEN_TEETH_WITH_WHAT',
      value: 'none',
    },
]

export default Object.freeze(responseOptions)
