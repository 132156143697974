import GenericYesNoOptionsList from '@/views/widgets/data/response_options/GenericYesNoOptionsList'
import GenericSatisfationOptionsList from '@/views/widgets/data/response_options/GenericSatisfationOptionsList'
import BloodPressureYesNoHighLowOptions from '@/views/widgets/data/response_options/BloodPressureYesNoHighLowOptions'
import DiabetesTypesOptionsList from '@/views/widgets/data/response_options/DiabetesTypesOptionsList'
import DentistLastTimeVisitedOptionsList from '@/views/widgets/data/response_options/DentistLastTimeVisitedOptionsList'
import DentistRegularityOptionsList from '@/views/widgets/data/response_options/DentistRegularityOptionsList'
import BrushingRegularityOptionsList from '@/views/widgets/data/response_options/BrushingRegularityOptionsList'
import CleaningBetweenTeethRegularityOptionsList from '@/views/widgets/data/response_options/CleaningBetweenTeethRegularityOptionsList'
import CleaningBetweenTeethWithWhatOptionsList from '@/views/widgets/data/response_options/CleaningBetweenTeethWithWhatOptionsList'
import UserHowIsClinicFoundOptionList from '@/views/widgets/data/response_options/UserHowIsClinicFoundOptionList'

import AnamnesisQuestions from '@/views/widgets/anamnesis_widget/data/AnamnesisQuestions'
import AnamnesisFormStepsDefinition from '@/views/widgets/data/steps/AnamnesisFormStepsDefinition'

const getters = {
  userResponses (state) {
    return state.userResponses
  },
  genericYesNoOptionsList (state) {
    return state.genericYesNoOptionsList
  },
  genericSatisfationOptionsList (state) {
    return state.genericSatisfationOptionsList
  },
  bloodPressureYesNoHighLowOptionList (state) {
    return state.bloodPressureYesNoHighLowOptionList
  },
  diabetesTypesOptionsList (state) {
    return state.diabetesTypesOptionsList
  },
  dentistLastTimeVisitedOptionsList (state) {
    return state.dentistLastTimeVisitedOptionsList
  },
  dentistRegularityOptionsList (state) {
    return state.dentistRegularityOptionsList
  },
  brushingRegularityOptionsList (state) {
    return state.brushingRegularityOptionsList
  },
  cleaningBetweenTeethRegularityOptionsList (state) {
    return state.cleaningBetweenTeethRegularityOptionsList
  },
  cleaningBetweenTeethWithWhatOptionsList (state) {
    return state.cleaningBetweenTeethWithWhatOptionsList
  },
  userHowIsClinicFoundOptionList (state) {
    return state.userHowIsClinicFoundOptionList
  },

  enumSteps (state) {
    return state.enumSteps
  },
}

const initialState = {
  userResponses: JSON.parse(JSON.stringify(AnamnesisQuestions)),

  includedQuestions: [],

  // User option lists (static)
  genericYesNoOptionsList: GenericYesNoOptionsList,
  genericSatisfationOptionsList: GenericSatisfationOptionsList,
  bloodPressureYesNoHighLowOptionList: BloodPressureYesNoHighLowOptions,
  diabetesTypesOptionsList: DiabetesTypesOptionsList,
  dentistLastTimeVisitedOptionsList: DentistLastTimeVisitedOptionsList,
  dentistRegularityOptionsList: DentistRegularityOptionsList,
  brushingRegularityOptionsList: BrushingRegularityOptionsList,
  cleaningBetweenTeethRegularityOptionsList: CleaningBetweenTeethRegularityOptionsList,
  cleaningBetweenTeethWithWhatOptionsList: CleaningBetweenTeethWithWhatOptionsList,
  userHowIsClinicFoundOptionList: UserHowIsClinicFoundOptionList,

  // Static
  enumSteps: AnamnesisFormStepsDefinition,
}

const mutations = {
  setUserResponse (state, payload) {
    const topic = payload.topic
    const response = payload.response
    if (state.userResponses[topic] === undefined) {
      throw Error(`Unknown user response topic: ${topic}`)
    }
    state.userResponses[topic].value = response
  },
  setIncludedQuestions (state, payload) {
    state.includedQuestions = payload
  },
  clear (state) {
    Object.assign(state, initialState)
  },
}

const actions = {
}

export default {
  namespaced: true,
  getters,
  state: { ...initialState },
  actions,
  mutations,
}
